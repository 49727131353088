// Styles for: typography
// Notes: typography style for all devices

body {
    @include text-copy;
    color: $text-copy;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2.2rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.6rem;
}

h5 {
    font-size: 1.4rem;
}

h6 {
    font-size: 1.2rem;
}

strong {
    font-weight: $bold;
}

p {
    line-height: 2;

    a {
        text-decoration: none;
        color: $primary;
        font-weight: $bold;

        @include hover {
            text-decoration: underline;
        }
    }
}

// CMS HEADINGS

@mixin cms-heading-1 {
    @include text-title;
    font-size: 3.2rem;
    line-height: 1.5;
    font-weight: $bold;
}

@mixin cms-heading-2 {
    @include text-title;
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: $bold;
}

@mixin cms-heading-3 {
    @include text-title;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: $bold;
}

@mixin cms-heading-4 {
    @include text-title;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: $bold;
}

@mixin cms-heading-5 {
    @include text-title;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: $bold;
}

@mixin cms-heading-6 {
    @include text-title;
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: $bold;
}

@mixin cms-paragraph {
    @include text-copy;
    font-size: 1.6rem;
    line-height: 2;
    font-weight: $regular;
}

@mixin cms-list {
    @include text-copy;
    font-size: 1.4rem;
    line-height: 2;
    font-weight: $regular;
}

// HEADINGS

@mixin headline-xxl {
    @include text-title;
    font-size: 9.6rem;
    line-height: 1.13;
    font-weight: $extrabold;

    @include mq($until: mobile) {
        font-size: 7.2rem;
        line-height: 1.11;
    }
}

@mixin headline-xl {
    @include text-title;
    font-size: 8rem;
    line-height: 1.15;
    font-weight: $extrabold;

    @include mq($until: mobile) {
        font-size: 6.4rem;
        line-height: 1.06;
    }
}

@mixin headline-1 {
    @include text-title;
    font-size: 6.4rem;
    line-height: 1.16;
    font-weight: $extrabold;

    @include mq($until: mobile) {
        font-size: 5.2rem;
        line-height: 1.15;
    }
}

@mixin headline-2 {
    @include text-title;
    font-size: 5.2rem;
    line-height: 1.15;
    font-weight: $extrabold;

    @include mq($until: mobile) {
        font-size: 4.4rem;
        line-height: 1.09;
    }
}

@mixin headline-3 {
    @include text-title;
    font-size: 4rem;
    line-height: 1.2;
    font-weight: $bold;

    @include mq($until: mobile) {
        font-size: 3.6rem;
        line-height: 1.11;
    }
}

@mixin headline-4 {
    @include text-title;
    font-size: 3.2rem;
    line-height: 1;
    font-weight: $bold;

    @include mq($until: mobile) {
        font-size: 2.8rem;
    }
}

@mixin headline-5 {
    @include text-title;
    font-size: 2.4rem;
    line-height: 1.33;
    font-weight: $bold;

    @include mq($until: mobile) {
        font-size: 2rem;
        line-height: 1.4;
    }
}

@mixin headline-6 {
    @include text-title;
    font-size: 2rem;
    line-height: 1.44;
    font-weight: $bold;

    @include mq($until: mobile) {
        font-size: 1.6rem;
        line-height: 1.5;
    }
}

// SUBTITLES

@mixin subtitle-1 {
    @include text-title;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: $regular;

    @include mq($until: mobile) {
        font-size: 1.4rem;
    }
}

@mixin subtitle-2 {
    @include text-title;
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: $bold;

    @include mq($until: mobile) {
        font-size: 1.4rem;
        line-height: 1.5;
    }
}

@mixin subtitle-3 {
    @include text-title;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: $bold;

    @include mq($until: mobile) {
        font-size: 1.2rem;
        line-height: 1.58;
    }
}

@mixin subtitle-4 {
    @include text-title;
    font-size: 1.2rem;
    line-height: 1.64;
    font-weight: $light;

    @include mq($until: mobile) {
        font-size: 1rem;
        line-height: 1.2;
    }
}

// PARAGRAPHS

@mixin paragraph-1 {
    @include text-copy;
    font-size: 2.1rem;
    line-height: 1.52;
    font-weight: $regular;

    @include mq($until: mobile) {
        font-size: 1.8rem;
        line-height: 1.5;
    }
}

@mixin paragraph-2 {
    @include text-copy;
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: $regular;

    @include mq($until: mobile) {
        font-size: 1.6rem;
        line-height: 1.5;
    }
}

@mixin paragraph-3 {
    @include text-copy;
    font-size: 1.6rem;
    line-height: 2;
    font-weight: $regular;

    @include mq($until: mobile) {
        font-size: 1.4rem;
        line-height: 1.5;
    }
}

@mixin paragraph-small {
    @include text-copy;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: $regular;
}

@mixin paragraph-tiny {
    @include text-copy;
    font-size: 1.2rem;
    line-height: 1.73;
    font-weight: $regular;

    @include mq($until: mobile) {
        font-size: 1rem;
        line-height: 1.2;
    }
}

// MISC

@mixin button {
    @include text-copy;
    font-size: 1.2rem;
    line-height: 1.25;
    font-weight: $bold;
    text-transform: uppercase;
}
