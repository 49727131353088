// Styles for: Font imports
// Notes: Font import

// stylelint-disable font-weight-notation, font-family-name-quotes

$light: 300;
$regular: 400;
$bold: 700;
$extrabold: 700;

// FUTURA PT - licensed typekit included in <head>
$titleFont: futura-pt, helvetica, sans-serif;
$bodyFont: futura-pt, helvetica, sans-serif;

@mixin text-title {
    font-family: $titleFont;
}

@mixin text-copy {
    font-family: $bodyFont;
}

.u-text-title {
    @include text-title;
}

.u-text-copy {
    @include text-copy;
}
