// Styles for: Carousel widget
// Notes: Carousel widget style for all devices

// CONFIG

// BACKGROUND
$carousel-background-colour: $grey-800 !default;

// SLIDE
$carousel-gradient-colour: $black !default;

// CAPTION
$carousel-caption-background-colour: $grey-200 !default;

// SUMMARY
$carousel-summary-colour: $dark-1 !default;

// NAVIGATION BUTTONS
$carousel-nav-button-background-colour: $primary !default;
$carousel-nav-button-icon-colour: $white !default;

.carousel {
    $this: &;
    position: relative;
    overflow: hidden;
    background-color: $carousel-background-colour;
    min-height: 73rem;

    &__video {
        @include object-fit(cover, center);
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__slide {
        background-color: $carousel-background-colour;
        min-height: 73rem;
        height: 73rem;
        align-items: center;
        justify-content: center;
        display: flex;
        z-index: 0;
        position: absolute;
        width: 100%;
        top: 0;
        overflow: hidden;
        padding: 0 13.5rem;
        opacity: 0;

        &::after {
            content: '';
            background: linear-gradient(180deg, rgba($carousel-gradient-colour, .1), rgba($carousel-gradient-colour, .4));
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        &.is-active {
            position: relative;
            z-index: $level-one;
            opacity: 1;

            #{$this}__content {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &__image-container {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__slide-info {
        z-index: $level-one;
        position: absolute;
        left: 10rem;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-evenly;
        top: 50%;
        transform: translateY(-50%);
    }

    &__content {
        text-align: center;
        opacity: 0;
        transition: opacity ease-in-out 1s, transform ease-in-out 1s;
        transform: translateY(2rem);
        z-index: $level-one;
    }

    &__title {
        position: relative;
        @include headline-xxl;
        color: $white;
        width: 100%;
        max-width: 100rem;
    }

    &__link {
        @include cta-primary;
        margin-top: $spacing-l;
        display: inline-block;
    }

    &__position {
        @include text-title;
        color: $white;
        font-weight: $bold;
        font-size: 2rem;
    }

    &__label {
        transform-origin: center;
        transform: rotate(-90deg);
        display: block;
        left: -.1rem;
        position: relative;
        min-width: 7rem;
    }

    &__caption {
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: $carousel-caption-background-colour;
        height: 7.2rem;
        min-height: 7.2rem;
        display: flex;
        align-items: center;
        padding: 0 22.3rem 0 2.4rem;
        width: 100%;
        max-width: 119rem;
        z-index: $level-one;
    }

    &__summary {
        @include paragraph-small;
        color: $carousel-summary-colour;
    }

    &__summary-prefix {
        @include subtitle-3;
        margin-right: $spacing-s;
    }

    &__controls {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        z-index: $level-one;
        height: 7.2rem;
    }

    &__button {
        border: none;
        background-color: $carousel-nav-button-background-colour;
        transition: background-color .25s ease-in-out;
        padding: 0 1.4rem;

        &:disabled {
            background-color: lighten($carousel-nav-button-background-colour, 4);
            pointer-events: none;

            svg {
                opacity: .5;
            }
        }

        svg {
            width: 1.6rem;
            height: 1.6rem;
            fill: $carousel-nav-button-icon-colour;
        }

        @include hover {
            color: $white;
            background-color: darken($carousel-nav-button-background-colour, 4);
        }
    }

    &__thumbnails {
        width: 13rem;
        height: 7.2rem;
        position: relative;
        background-color: $carousel-background-colour;
    }

    &__thumbnail {
        width: 100%;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border: none;
        transition: opacity .25s ease-in-out;

        &::after {
            content: '';
            background: linear-gradient(180deg, rgba($carousel-gradient-colour, .1), rgba($carousel-gradient-colour, .4));
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        &.is-active {
            display: flex;
        }

        @include hover {
            opacity: .9;
        }

        .lazy-image-wrapper {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__thumbnail-title {
        font-size: 1.6rem;
        position: relative;
        z-index: $level-two;
        color: $white;
        padding: .5rem 1rem;
    }

    &__info-bar {
        position: absolute;
        left: 0;
    }

    &__progress {
        margin: 6rem auto 4rem;
        background-color: rgba($color: $white, $alpha: .4);
    }

    &__progress-bar {
        height: 20rem;
        background-color: $white;
        display: block;
        width: .2rem;
        transform-origin: bottom;
        transform: scaleY(0);
        z-index: $level-one;

        &.is-animating {
            animation-name: progress;
            animation-timing-function: linear;
        }

        &.is-paused {
            animation-play-state: paused;
        }
    }

    &__play,
    &__pause {
        background-color: transparent;
        border: none;
        padding: 0;
        transition: transform .25s ease-in-out;

        @include hover {
            transform: scale(1.2);
        }

        &.is-hidden {
            display: none;
        }

        .icon {
            width: 4rem;
            height: 4rem;
            fill: $white;
        }
    }

    &__scroll {
        border: none;
        background-color: transparent;
        position: absolute;
        bottom: 2rem;
        z-index: $level-one;
        transition: transform .25s ease-in-out;
        left: 12.4rem;

        @include hover {
            transform: scale(1.2);
        }

        .icon {
            width: 2.4rem;
            height: 2.4rem;
            fill: $white;
        }
    }
}

@include mq($until: 1500px) {

    .carousel {

        &__slide-info {
            left: 3rem;
        }

        &__scroll {
            left: 5.4rem;
        }
    }
}

@include mq($until: 1500px) {

    .carousel {

        &__scroll {
            bottom: 10.7rem;
        }
    }
}

@include mq($until: tablet) {

    .carousel {
        min-height: 65rem;
        height: 65rem;

        &__slide-info {
            left: unset;
            transform-origin: center;
            transform: rotate(90deg) translateX(-100%);
            top: 5rem;
        }

        &__slide {
            min-height: 65rem;
            height: 65rem;
            padding: 0 4.5rem;
        }

        &__progress-bar {
            height: 10rem;
        }

        &__caption {
            display: none;
        }

        &__title {
            font-size: 6rem;
        }

        &__play,
        &__pause {
            transition: none;
            transform: rotate(-90deg);

            @include hover {
                transform: rotate(-90deg);
            }
        }

        &__scroll {
            display: none;
        }
    }
}
