// Styles for: object fit cover picture
// Notes: object fit cover picture style for all devices

.object-fit-cover-picture {

    &__img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        @include object-fit( cover, center );
    }
}
