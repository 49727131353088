// stylelint-disable at-rule-empty-line-before

$use-px: false;

/* SETTINGS
========================================================================== */

@import 'includes/settings/normalize';

@import 'includes/settings/variables';

@import 'includes/global/mixins';

/* VENDORS
========================================================================== */

@import 'includes/vendors/datepicker';

@import 'includes/vendors/auto-complete';

/* LAYOUT
========================================================================== */

@import 'includes/layout/mq';

@import 'includes/layout/grids';

@import 'includes/layout/map';

/* GLOBAL
========================================================================== */

@import 'includes/global/fonts';

@import 'includes/global/global';

@import 'includes/global/typography';

@import 'includes/global/loader';

@import 'includes/global/notice';

@import 'includes/global/animations';

@import 'includes/global/transitions';

@import 'includes/global/cms-content';

/* THEMES
========================================================================== */

@import "includes/themes/_example-theme.scss";
@import "includes/themes/_theme-function.scss";
@import "includes/themes/_theme-functions.scss";

/* BRAND
========================================================================== */

@import 'includes/brand/config';

/* SPRITES
========================================================================== */

// @import 'includes/sprites/sprite-includes';

/* COMPONENTS
========================================================================== */

@import "includes/components/_buttons.scss";
@import "includes/components/_datepicker.scss";
@import "includes/components/_embed-widgets.scss";
@import "includes/components/_forms.scss";
@import "includes/components/_object-fit-cover-picture.scss";
@import "includes/components/_parallax.scss";
@import "includes/components/_picture.scss";

/* WIDGETS
========================================================================== */
/** If widget folder structure is changed this needs updating **/

@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/blog/styles/_blog.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/booking/styles/_booking.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/carousel/styles/_carousel.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/content-carousel/styles/_content-carousel.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/content/styles/_content.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/cookie-notice/styles/cookie-notice.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/embeddable-photo/styles/_embeddable-photo.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/embeddable-quote/styles/_embeddable-quote.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/featured-articles/styles/_featured-articles.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/featured-content/styles/_featured-content.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/featured-content/styles/_featured-tile.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/footer/styles/_footer.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/header/styles/_header.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/hotel-listing/styles/_hotel-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/lead-image/styles/_lead-image.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/mixed-content-listing/styles/_mixed-content-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/region-listing/styles/_region-listing.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/share/styles/_share.scss";
@import "/opt/atlassian/pipelines/agent/build/src/widgets/sites/expedia-atout/video/styles/_video.scss";

/* WRAPS
========================================================================== */



/* UTILITIES
========================================================================== */

@import "includes/utilities/_shame.scss";
@import "includes/utilities/_trumps.scss";

// stylelint-enable
