// Styles for: Content widget
// Notes: Content widget style for all devices

// CONTENT
$content-background-colour: $white !default;
$content-text-colour: $dark-1 !default;

.content {
    background-color: $content-background-colour;

    .wrapper {
        max-width: 72rem;
        margin: 0 auto;
        padding: 8rem $spacing-m;
    }

    &__content {
        text-align: center;
        color: $content-text-colour;
    }

    .cms-content {

        h1 {
            margin-bottom: 1.6rem;
            font-size: 2.4rem;
        }

        p {
            line-height: 2;
        }
    }
}

@include mq($until: phablet) {

    .content {

        .wrapper {
            padding-top: 6rem;
            padding-bottom: 4rem;
        }

        &__content {

            h2,
            p {
                font-size: 1.4rem;
                line-height: 1.7;
            }
        }
    }
}
