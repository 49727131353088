// Brand: Name
// Notes: If you need to customise particular widget styling for the brand 
// please add the required variables from the widgets .scss file below and 
// assign new values. Also remove the !default flag. Example below:

// FOOTER
$footer-background-colour: $primary;

// CONTENT
$content-background-colour: $grey-200;

// CONTENT CAROUSEL
$content-carousel-background-color: $primary;
$content-carousel-article-summary-size: 1.6rem;
$content-carousel-article-summary-size-mobile: 1.6rem;

// CONTENT CAROUSEL - NAVIGATION
$content-carousel-navigation-button-color: transparent;
$content-carousel-navigation-icon-color: $white;
$content-carousel-indicator-color: $light-1;

// FEATURED ARTICLES
$featured-articles-background-colour: $grey-200;

// FEATURED CONTENT
$featured-content-background-colour: $grey-200;

// REGION LISTING
$region-listing-background-colour: $grey-200;
$region-listing-regions-scrollbar: $white;
$region-listing-arrow-button-colour: $primary;
$region-listing-arrow-button-colour-hover: $primary;
$region-listing-arrow-button-icon-colour-hover: $white;
$region-listing-arrow-button-line-colour: $primary;
$region-listing-arrow-button-icon-colour: $white;
$region-listing-arrow-button-border-radius: 1.2rem;

// HOTEL LISTING
$hotel-listing-background-colour: $grey-200;
$hotel-listing-paging-button-background-colour: $primary;
$hotel-listing-paging-button-outline: $primary;
$hotel-listing-paging-button-icon-colour: $white;
$hotel-listing-paging-button-background-colour-hover: $primary-hover;
$hotel-listing-hotel-card-outline: $grey-200;
$hotel-listing-hotels-scrollbar: $white;
$hotel-listing-hotel-card-image-background: $light-2;
