// Styles for: Embeddable photo widget
// Notes: Embeddable photo widget style for all devices

// CONFIG

$embeddable-photo-background-colour: $black !default;
$embeddable-photo-caption-colour: $grey-700 !default;

// EMBEDDABLE PHOTO

.embeddable-photo {

    &__title {
        font-size: 2rem;
        margin-bottom: 3rem;
    }

    &__figure {
        position: relative;

        .lazy-image-wrapper {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__image-wrapper {
        position: relative;
        background-color: $embeddable-photo-background-colour;
        border-right: .6rem solid $primary;
        border-left: .6rem solid $primary;

        &::before {
            content: '';
            display: block;
            padding-bottom: ( 9 / 16 ) * 100%;
        }
    }

    &__caption {
        font-size: 1.4rem;
        line-height: 2;
        font-style: italic;
        text-align: left;
        color: $embeddable-photo-caption-colour;
        margin-top: $spacing-xs;
    }

    &__summary {
        font-style: italic;
        margin-top: 3rem;
    }

    @include mq($until: 1200px) {

        &__caption {
            position: static;
            width: auto;
            margin-top: 2rem;
        }
    }

    @include mq($until: tablet) {

        &__caption {
            margin-top: $spacing-xxs;
        }
    }
}
