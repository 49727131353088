// Styles for: trumps
// Notes: trumps style for all devices

/* stylelint-disable declaration-no-important  */

.u-hide {
    display: none !important;
}

.u-show {
    display: block !important;
}

.u-visually-hidden {
    visibility: hidden;
}

.u-observed {
    min-width: .1rem;
    min-height: .1rem;
}

.u-screen-reader {
    border: 0;
    clip: rect(0 0 0 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: .1rem;
}

.u-overflow-hidden {
    overflow: hidden !important;
    height: 100%;
}

@include mq($until: wide) {

    .u-hide-wide {
        display: none !important;
    }
}

.u-no-font {
    font-size: 0;
}

.u-text-upper {
    text-transform: uppercase !important;
}

.u-flex-tablet {
    @include mq( $until: tablet ) {
        display: flex;
        flex-direction: column;
    }
}

@include mq($until: desktop) {

    .u-hide-desktop {
        display: none;
    }
}

@include mq($from: desktop) {

    .u-show-desktop {
        display: none;
    }
}

@include mq($until: tablet) {

    .u-body-no-scroll {
        position: fixed;
    }

    .u-hide-tablet {
        display: none;
    }

    .u-display-tablet {
        display: inline-block !important;
    }
}

@include mq($from: tablet) {

    .u-show-tablet {
        display: none !important;
    }
}

@include mq($until: phablet) {

    .u-hide-phablet {
        display: none !important;
    }
}

@include mq($from: phablet) {

    .u-show-phablet {
        display: none !important;
    }
}

@include mq($until: desktop) {

    .u-hide-desktop {
        display: none !important;
    }
}

@include mq($from: mobile) {

    .u-show-mobile {
        display: none;
    }
}

@include mq($until: mobile) {

    .u-hide-mobile {
        display: none;
    }
}
/* stylelint-enable */
