// Styles for: Video widget
// Notes: Video widget style for all devices

// CONFIG

$video-header-line-colour: $grey-300 !default;
$video-type-colour: $dark-1 !default;
$video-type-heading-colour: $dark-1 !default;
$video-meta-colour: $grey-700 !default;
$video-cta-line-colour: $grey-300 !default;

// VIDEO

.video {
    margin-top: 14rem;

    &__header {
        margin: 0 auto 3.2rem;
        padding-bottom: 3.2rem;
        border-bottom: .1rem solid $video-header-line-colour;
    }

    &__intro {
        margin: 0;
        text-align: center;
    }

    &__type {
        font-size: 1.4rem;
        font-weight: $bold;
        color: $video-type-colour;
    }

    &__heading {
        font-size: 5.2rem;
        line-height: 1;
        max-width: 89rem;
        margin: 0 auto 2.4rem;
        color: $video-type-heading-colour;
    }

    &__meta {
        @include clearfix();
        font-size: 1.4rem;
        line-height: 1.5;
        max-width: 37rem;
        margin: 0 auto;
    }

    &__date,
    &__author {
        display: block;
        color: $video-meta-colour;
    }

    &__date {
        font-weight: $bold;
    }

    &__content {
        position: relative;
        max-width: 72rem;
        margin: 0 auto;
        text-align: center;
    }

    &__share {
        position: absolute;
        top: 0;
        right: -38rem;
        width: 8rem;
        min-height: 30rem;
    }

    &__cta {
        position: relative;
        max-width: 136rem;
        margin: 0 auto;
        padding: 4.4rem 0;
        text-align: center;

        &::before {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            width: 100%;
            height: .1rem;
            margin: 0 auto;
            content: '';
            background-color: $video-cta-line-colour;
        }
    }

    &__cta-button {
        @include cta-primary;
    }

    // YOUTUBE

    .youtubeThumb {
        z-index: $level-one;
        height: 73.8rem;
        margin-right: -29.6rem;
        margin-left: -29.6rem;
    }
}

@include mq($until: 1500px) {

    .video {

        &__header,
        &__cta {
            max-width: 90rem;
        }

        &__share {
            right: -17rem;
        }

        .youtubeThumb {
            height: 50.6rem;
            margin-right: -9rem;
            margin-left: -9rem;
        }
    }
}

@include mq($until: 1100px) {

    .video {

        &__header,
        &__cta {
            max-width: 100%;
        }

        &__share {
            right: -10rem;
        }

        .youtubeThumb {
            height: auto;
            margin-right: 0;
            margin-left: 0;
        }
    }
}

@include mq($until: 1000px) {

    .video {

        &__share {
            display: none;
        }
    }
}

@include mq($until: tablet) {

    .video {
        margin-top: 9rem;

        &__heading {
            font-size: 4.2rem;
        }
    }
}

@include mq($until: phablet) {

    .video {

        &__heading {
            font-size: 3rem;
            margin-bottom: 1.2rem;
        }

        &__meta {
            max-width: 24rem;
            margin-bottom: 1.2rem;
        }
    }
}
