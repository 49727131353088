// Styles for: Map
// Notes: Map style for all devices

.map-experience {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: $grey-300;

    @include mq($until: phablet) {
        justify-content: center;
    }
}
