// Styles for: Button
// Notes: Generic button style

// stylelint-disable selector-max-specificity, selector-max-type, selector-no-qualifying-type, selector-max-universal

// BUTTON

button {
    background-color: transparent;
    border: none;
    padding: 0;
}

%cta,
.cta {
    @include button;
    padding: 1.4rem $spacing-xl;
    display: inline-block;
    text-align: left;
    white-space: normal;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;
    background-image: none;
    border: none;
    border-radius: 2.2rem;
    color: $white;
    background-color: $primary;
    transition: all .25s ease-in-out;
    position: relative;
    text-decoration: none;

    &.disabled {
        opacity: .5;
        cursor: not-allowed;
    }

    svg {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: 50%;
        left: 3rem;
        transform: translateY(-50%);
    }
}

// BUTTON PRIMARY

@mixin cta-primary {
    @extend %cta;
    color: $white;
    background-color: $primary;

    @include hover {
        color: $white;
        background-color: $primary-hover;
    }
}

.cta-primary {
    @include cta-primary();
}

// BUTTON PRIMARY OUTLINE

@mixin cta-primary-outline {
    @extend %cta;
    color: $primary;
    background-color: transparent;
    border: .1rem solid $primary;

    @include hover {
        color: $white;
        background-color: $primary;
    }
}

.cta-primary-outline {
    @include cta-primary-outline();
}

// BUTTON SECONDARY

@mixin cta-secondary {
    @extend %cta;
    color: $white;
    background-color: $secondary;

    @include hover {
        color: $white;
        background-color: $secondary-hover;
    }
}

.cta-secondary {
    @include cta-secondary();
}

// BUTTON SECONDARY OUTLINE

@mixin cta-secondary-outline {
    @extend %cta;
    color: $secondary;
    background-color: transparent;
    border: .1rem solid $secondary;

    @include hover {
        color: $white;
        background-color: $secondary;
    }
}

.cta-secondary-outline {
    @include cta-secondary-outline();
}

// BUTTON TERTIARY

@mixin cta-tertiary {
    @extend %cta;
    color: $white;
    background-color: $tertiary;

    @include hover {
        color: $white;
        background-color: $tertiary-hover;
    }
}

.cta-tertiary {
    @include cta-tertiary();
}

// BUTTON TERTIARY OUTLINE

@mixin cta-tertiary-outline {
    @extend %cta;
    color: $tertiary;
    background-color: transparent;
    border: .1rem solid $tertiary;

    @include hover {
        color: $white;
        background-color: $tertiary;
    }
}

.cta-tertiary-outline {
    @include cta-tertiary-outline();
}

// BUTTON QUATERNARY

@mixin cta-quaternary {
    @extend %cta;
    color: $white;
    background-color: $quaternary;

    @include hover {
        color: $white;
        background-color: $quaternary-hover;
    }
}

.cta-quaternary {
    @include cta-quaternary();
}

// BUTTON QUATERNARY OUTLINE

@mixin cta-quaternary-outline {
    @extend %cta;
    color: $quaternary;
    background-color: transparent;
    border: .1rem solid $quaternary;

    @include hover {
        color: $white;
        background-color: $quaternary;
    }
}

.cta-quaternary-outline {
    @include cta-quaternary-outline();
}

// BUTTON WHITE

@mixin cta-white {
    @extend %cta;
    color: $tertiary;
    background-color: $light-1;

    @include hover {
        color: $tertiary;
        background-color: $light-1-hover;
    }
}

.cta-white {
    @include cta-white();
}

// BUTTON WHITE OUTLINE

@mixin cta-white-outline {
    @extend %cta;
    color: $light-1;
    background-color: transparent;
    border: .1rem solid $light-1;

    @include hover {
        color: $tertiary;
        background-color: $light-1;
    }
}

.cta-white-outline {
    @include cta-white-outline();
}

// BUTTON DARK 1

@mixin cta-dark-1 {
    @extend %cta;
    color: $white;
    background-color: $dark-1;

    @include hover {
        color: $white;
        background-color: $dark-1-hover;
    }
}

.cta-dark-1 {
    @include cta-dark-1();
}

// BUTTON DARK 1 OUTLINE

@mixin cta-dark-1-outline {
    @extend %cta;
    color: $dark-1;
    background-color: transparent;
    border: .1rem solid $dark-1;

    @include hover {
        color: $white;
        background-color: $dark-1;
    }
}

.cta-dark-1-outline {
    @include cta-dark-1-outline();
}

// BUTTON DARK 2

@mixin cta-dark-2 {
    @extend %cta;
    color: $white;
    background-color: $dark-2;

    @include hover {
        color: $white;
        background-color: $primary;
    }
}
