.parallax {
    position: relative;
    border-radius: .4rem;
    overflow: hidden;

    --base-duration: 500ms;
    --base-ease: cubic-bezier(.25, .46, .45, .84);
    transition: transform calc(var(--base-duration) / 4) var(--base-ease);

    &:hover {
        --d: 50;
        transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    }

    img {
        max-width: none;
        width: 110%;
        height: 110%;
        top: -5%;
        left: -5%;
        transition: transform var(--base-duration) var(--base-ease);
        --d: 20;
        transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    }

    &__content {
        transition: transform var(--base-duration) var(--base-ease);
        --d: 60;
        transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
    }
}
