// Styles for: Featured Content widget
// Notes: Featured Content widget style for all devices

// BACKGROUND
$featured-content-background-colour: $white !default;

// HEADER COLOURS
$featured-content-title-colour: $dark-1 !default;
$featured-content-subtitle-colour: $dark-1 !default;

// SCROLLBAR
$featured-content-hotels-scrollbar: $light-1 !default;
$featured-content-hotels-scrollbar-thumb: $primary !default;

// SCROLLBAR
$hotel-listing-hotels-scrollbar: $light-1 !default;
$hotel-listing-hotels-scrollbar-thumb: $primary !default;

// ITEM MIN-WIDTH
$featured-content-item-min-width: 28.3rem !default;

.featured-content {
    padding-top: $spacing-xxl;
    padding-bottom: $spacing-xxl;
    background-color: $featured-content-background-colour;
    margin-bottom: 0;
    overflow-x: hidden;

    &__header {
        margin-bottom: $spacing-xxl;
        text-align: center;
    }

    &__title {
        @include headline-4;
        color: $featured-content-title-colour;
        margin: 0 auto 2.2rem;
        max-width: 66rem;
    }

    &__subtitle {
        @include subtitle-1;
        color: $featured-content-subtitle-colour;
        margin: 2.2rem auto 0;
        max-width: 66rem;
    }

    &__row {
        display: flex;
        margin: 0 -$spacing-xl/2;
    }

    &__item {
        margin: 0 $spacing-xl/2;
        flex: 1 1 33.33%;
        min-width: 0;
    }

    @include mq($until: tablet) {

        &__row {
            margin: 0 ( -$spacing-l/2 - 1.6rem );
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            scroll-padding: $spacing-l + 1.6rem;
            padding-bottom: 2rem;

            &::-webkit-scrollbar {
                height: .3rem;
                background: $featured-content-hotels-scrollbar;
            }

            &::-webkit-scrollbar-thumb {
                color: $featured-content-hotels-scrollbar-thumb;
                background: $featured-content-hotels-scrollbar-thumb;
            }

            &::before,
            &::after {
                content: '';
                display: block;
                flex: 0 0 $spacing-m;
            }
        }

        &__item {
            flex: 1 0 $featured-content-item-min-width;
            scroll-snap-align: center;
            margin: 0 $spacing-l/2;
        }
    }

    @include mq($until: phablet) {
        padding-top: $spacing-xl;
        padding-bottom: $spacing-xl;
    }
}
