// Styles for: Header widget
// Notes: Header widget style for all devices
// stylelint-disable selector-max-specificity, declaration-no-important, no-duplicate-selectors, max-empty-lines

// CONFIG

$header-background-colour: $white !default;
$header-top: $spacing-xl !default;

$header-container-expanded-max-width: 150rem !default;
$header-container-width: $wrapper-width !default;
$header-container-sticky-border-bottom: $grey-300 !default;
$header-container-sticky-border-bottom-height: .1rem !default;

$header-navigation-dropdown-colour: $white !default;
$header-navigation-dropdown-width: 30rem !default;

$header-navigation-link-background-colour: $white !default;
$header-navigation-link-colour: $grey-900 !default;
$header-navigation-link-font-weight: $bold !default;
$header-navigation-link-icon-fill: $grey-900 !default;
$header-navigation-link-size: 1.4rem !default;
$header-navigation-link-text-transform: uppercase !default;
$header-navigation-link-underline-active: $primary !default;
$header-navigation-link-underline-height: .2rem !default;
$header-navigation-link-underline-hover: $primary !default;

$header-navigation-sublink-background-colour-hover: $primary !default;
$header-navigation-sublink-colour-hover: $white !default;
$header-navigation-sublink-colour: $grey-900 !default;
$header-navigation-sublink-underline-active: $primary !default;
$header-navigation-sublink-underline-height: .1rem !default;
$header-navigation-sublink-underline-hover: $primary !default;

$header-navigation-mobile-menu-background-colour: $white !default;
$header-navigation-mobile-menu-colour: $grey-900 !default;
$header-navigation-mobile-menu-fill: $grey-900 !default;
$header-navigation-mobile-menu-font-weight: $bold !default;
$header-navigation-mobile-menu-underline-active: $primary !default;
$header-navigation-mobile-menu-underline-height: .2rem !default;

$header-navigation-mobile-menu-link-underline-colour: $light-1 !default;
$header-navigation-mobile-menu-link-underline-height: .2rem !default;
$header-navigation-mobile-menu-link-underline-hover: $primary !default;

$header-navigation-mobile-menu-sublink-background-colour-hover: $white !default;
$header-navigation-mobile-menu-sublink-colour-hover: $grey-900 !default;
$header-navigation-mobile-menu-sublink-colour-active: $primary !default;
$header-navigation-mobile-menu-sublink-colour: $grey-900 !default;
$header-navigation-mobile-menu-sublink-underline-height: .2rem !default;

// HEADER

.header {
    $this: &;
    position: absolute;
    z-index: $level-two;
    top: $header-top;
    width: 100%;

    // CONTAINER

    &__container {
        position: absolute;
        right: 0;
        left: 0;
        width: 100%;
        max-width: $header-container-width;
        margin: auto;
        padding: 0 $spacing-xs;
        transition: max-width 1s;
        background-color: $header-background-colour;

        &.is-glued {
            position: fixed;
            top: 0;
            max-width: 100%;
            border-bottom: $header-container-sticky-border-bottom-height solid $header-container-sticky-border-bottom;
        }
    }

    // CONTENT

    &__content {
        display: flex;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
        max-width: $header-container-expanded-max-width;
        margin: 0 auto;
    }

    // LOGOS

    &__brand,
    &__pos {
        position: relative;
        margin: 0 $spacing-m;
        width: 11.5rem;
        display: flex;
        align-items: center;

        &-image {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 100%;
        }

        &::after {
            display: block;
            content: '';
            width: 100%;
            padding-top: (9 / 16) * 100%;
        }
    }

    // MENU

    &__menu {
        flex-grow: 1;
        height: 100%;
        margin-left: $spacing-m;
    }

    // CTA

    &__cta {
        display: flex;
        margin: 0 $spacing-m;
    }

    &__button {
        @include cta-primary;
    }

    // MOBILE MENU

    .menu {
        display: none;
    }

    .linked-list {
        display: flex;
        align-items: flex-start;
        height: 100%;
        text-align: center;

        // ITEM

        &__item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            // HOVER OF THE ITEM

            @include hover {

                .linked-list__dropdown {
                    transform: translateY(0) translateX(-50%);
                    pointer-events: auto;
                    opacity: 1;
                }
            }
        }

        // LEVEL 1

        &__dropdown-label,
        &__link {
            font-size: $header-navigation-link-size;
            font-weight: $header-navigation-link-font-weight;
            width: 100%;
            margin: 0 1rem;
            padding: 3.5rem $spacing-m 3.4rem;
            transition: border-bottom ease .25s;
            text-decoration: none;
            text-transform: $header-navigation-link-text-transform;
            color: $header-navigation-link-colour;
            border: none;
            border-bottom: $header-navigation-link-underline-height solid transparent;
            background-color: $header-navigation-link-background-colour;
            appearance: none;

            @include hover {
                border-bottom: $header-navigation-link-underline-height solid $header-navigation-link-underline-hover;
            }

            &.is-active {
                border-bottom: $header-navigation-link-underline-height solid $header-navigation-link-underline-active;
            }
        }

        &__button-arrow {
            position: relative;
            top: .1rem;
            width: 1.2rem;
            height: 1.2rem;
            margin-left: $spacing-s;
            fill: $header-navigation-link-icon-fill;
        }

        // LEVEL 2

        &__items {
            padding: $spacing-xs;
            background-color: $header-navigation-dropdown-colour;

            .linked-list {

                &__link {
                    line-height: normal;
                    margin: 0;
                    padding: 1.2rem $spacing-s 1.3rem;
                    transition: color ease-in-out .25s, border-bottom ease-in-out .25s, background-color ease-in-out .25s;
                    text-transform: none;
                    border-bottom: $header-navigation-sublink-underline-height solid $light-1;

                    @include hover {
                        color: $header-navigation-sublink-colour-hover;
                        border-bottom: $header-navigation-sublink-underline-height solid $header-navigation-sublink-underline-hover;
                        background-color: $header-navigation-sublink-background-colour-hover;
                    }

                    &.is-active {
                        border-bottom: $header-navigation-sublink-underline-height solid $header-navigation-sublink-underline-active;
                    }
                }
            }
        }

        // DROPDOWN

        &__dropdown {
            position: absolute;
            top: 100%;
            left: 50%;
            width: $header-navigation-dropdown-width;
            transition: opacity .25s cubic-bezier(0, .5, .6, 1), transform .25s cubic-bezier(0, .5, .6, 1);
            transform: translateY(1rem) translateX(-50%);
            pointer-events: none;
            opacity: 0;
            box-shadow: 0 .2rem 2.4rem 0 rgba($black, .15);
            z-index: $level-one;

            &:focus-within {
                transform: translateY(0) translateX(-50%);
                pointer-events: auto;
                opacity: 1;
            }
        }

        &__dropdown-arrow {
            position: absolute;
            top: -1.7rem;
            right: 0;
            left: 0;
            width: 2.4rem;
            height: 2.4rem;
            margin: auto;
            fill: $header-navigation-dropdown-colour;
        }

        // DROPDOWN STATES

        // FOCUS ON THE DROPDOWN

        &__dropdown-label {

            @include hover {

                + .linked-list__dropdown {
                    transform: translateY(0) translateX(-50%);
                    pointer-events: auto;
                    opacity: 1;
                }
            }
        }
    }

    // VARIANTS

    &--brand-cta,
    &--brand-cta-pos {

        #{$this}__container {
            padding: $spacing-s $spacing-xs;
        }

        #{$this}__cta {
            margin-left: auto;
        }
    }
}

@include mq($until: 1200px) {

    .header {

        .linked-list {

            &__dropdown-label,
            &__link {
                margin: 0;
                padding: 3rem $spacing-m 2.8rem;
            }

            &__button-arrow {
                top: .2rem;
                margin-left: .6rem;
            }
        }

        &__brand,
        &__pos,
        &__cta {
            margin: 0 $spacing-xs;
        }
    }
}

@include mq($until: desktop, $from: tablet) {

    .header {

        &__brand,
        &__pos {
            width: 7.3rem;
        }

        .linked-list {

            &__dropdown-label,
            &__link {
                font-size: 1.2rem;
            }
        }
    }
}

@include mq($until: tablet) {

    .header {
        $this: &;
        position: fixed;
        top: 0;

        &__content {
            height: 6.4rem;
            justify-content: flex-start;
        }

        &__container.is-glued {
            box-shadow: none;
        }

        &--open-menu {

            .header {

                &__menu {
                    pointer-events: all;
                    opacity: 1;
                }

                &__cta {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            &.header {

                .menu {
                    border-bottom: $header-navigation-mobile-menu-underline-height solid $header-navigation-mobile-menu-underline-active;

                    &__chevron {
                        display: none;
                    }

                    &__close {
                        display: block;
                    }
                }
            }
        }

        // LOGOS

        &__brand,
        &__pos {
            width: 8rem;
        }

        &__brand {
            order: 1;
        }

        &__pos {
            order: 1;
        }

        // CTA

        &__cta {
            position: fixed;
            bottom: 0;
            left: 0;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            transition: transform ease-in-out .25s, opacity ease-in-out .25s;
            transform: translateY(100%);
            margin: 0;
            opacity: 0;
        }

        &__button-container {
            padding: $spacing-l $spacing-xs;
            background-color: $header-navigation-mobile-menu-background-colour;
        }

        &__button {
            width: 100%;
            text-align: center;
        }

        // MENU

        &__menu {
            position: fixed;
            top: 0;
            left: 0;
            overflow: auto;
            width: 100%;
            height: calc(100% - 15rem);
            margin-top: $spacing-xxl;
            transition: opacity ease-in-out .25s;
            pointer-events: none;
            opacity: 0;
            background-color: $header-navigation-mobile-menu-background-colour;
            margin-left: 0;
        }

        // MOBILE MENU

        .menu {
            order: 2; // ensure this is the last item in the .header__content flex container
            margin-left: auto; // align this to the right within the flex container
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 $spacing-xs;
            transition: border-bottom ease .25s;
            text-decoration: none;
            border: none;
            border-bottom: $header-navigation-mobile-menu-underline-height solid transparent;
            background-color: transparent;
            appearance: none;

            &__text {
                font-size: 1.4rem;
                font-weight: $header-navigation-mobile-menu-font-weight;
                text-transform: uppercase;
                color: $header-navigation-mobile-menu-colour;
            }

            &__icon {
                position: relative;
                width: 1.4rem;
                height: 1.4rem;
                margin: 0 0 0 1rem;
            }

            &__close {
                display: none;
                width: 1.2rem;
                height: 1.2rem;
            }
        }

        .linked-list {
            align-items: center;
            flex-direction: column;
            justify-content: center;
            height: auto;
            margin: 0 auto;
            padding: 0 $spacing-xs;

            &__item {
                flex-direction: column;
                width: 100%;
            }

            // LEVEL 1

            &__link,
            &__dropdown-label {
                position: relative;
                padding: 1.9rem $spacing-m 1.8rem;
                border-bottom: $header-navigation-mobile-menu-link-underline-height solid $header-navigation-mobile-menu-link-underline-colour;

                @include hover {
                    border-bottom: $header-navigation-mobile-menu-link-underline-height solid $header-navigation-mobile-menu-link-underline-hover;
                }
            }

            &__dropdown-label {

                &.is-open .linked-list__button-arrow {
                    transform: rotate(180deg) translateY(50%);
                }
            }

            &__button-arrow {
                position: absolute;
                top: 50%;
                right: $spacing-l;
                margin: 0;
                transition: ease-in-out transform .25s;
                transform: translateY(-50%);
                fill: $header-navigation-mobile-menu-fill;
            }

            // LEVEL 2

            &__items {
                padding: 0;

                .linked-list {

                    &__item {
                        margin: 0;
                    }

                    &__link {
                        font-size: 1.4rem;
                        padding: 1.3rem 1rem 1.4rem;
                        border-bottom: none;

                        @include hover {
                            text-decoration: none;
                            color: $header-navigation-mobile-menu-sublink-colour-hover;
                            background-color: $header-navigation-mobile-menu-sublink-background-colour-hover;
                        }

                        &.is-active {
                            color: $header-navigation-mobile-menu-sublink-colour-active;
                            border-bottom: $header-navigation-mobile-menu-sublink-underline-height solid transparent;
                        }
                    }
                }
            }

            // DROPDOWN

            &__dropdown {
                position: static;
                display: none;
                transform: none !important;
                pointer-events: initial;
                opacity: 1;
                box-shadow: none;

                &.is-open {
                    left: 0;
                    display: block;
                    width: 100%;
                }
            }

            &__dropdown-arrow {
                display: none;
            }
        }

        // VARIANTS

        &--brand-cta,
        &--brand-cta-pos {

            #{$this}__container {
                padding: 0 $spacing-xs;
            }

            #{$this}__button-container {
                padding: 0;
            }

            #{$this}__cta {
                position: initial;
                bottom: unset;
                left: unset;
                width: auto;
                transform: none;
                margin: 0 $spacing-xs;
            }
        }
    }
}

@include mq($until: mobile) {

    .header {

        &__brand,
        &__pos {
            width: 6.8rem;
        }
    }
}
