// Styles for: Blog widget
// Notes: Blog widget style for all devices

// CONFIG

// HEADER
$blog-header-background-colour: $black !default;
$blog-header-image-shim-colour: $black !default;

// HEADER CONTENT
$blog-header-title-colour: $white !default;
$blog-header-summary-colour: $white !default;
$blog-header-meta-colour: $white !default;

// HEADER META
$blog-header-meta-border-top-colour: $white !default;
$blog-header-meta-border-top-height: .1rem !default;
$blog-header-meta-divider: '•' !default;

// CTA
$blog-cta-line-colour: $grey-300 !default;

// PROGRESS BAR
$blog-progress-bar-line-colour: $primary !default;
$blog-progress-bar-colour: $grey-400 !default;

// BLOG

.blog {

    &__header {
        position: relative;
        height: 100vh;
        min-height: 60rem;
        margin-bottom: 9.6rem;
        max-height: 86.8rem;
        background-color: $blog-header-background-colour;

        &::before {
            position: absolute;
            z-index: $level-one;
            bottom: 0;
            display: block;
            width: 100%;
            height: 60%;
            content: '';
            background-image: linear-gradient(to bottom, rgba($blog-header-image-shim-colour, 0) 25%, rgba($blog-header-image-shim-colour, .8) 75%);
        }
    }

    &__image {

        .lazy-image-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    &__intro {
        position: absolute;
        z-index: $level-one;
        right: 0;
        bottom: 0;
        left: 0;
        max-width: 120rem;
        margin-bottom: 0;
    }

    &__heading {
        @include headline-2;
        max-width: 87rem;
        margin-bottom: $spacing-xl;
        color: $blog-header-title-colour;

        &--small {
            @include headline-3;
        }
    }

    &__summary {
        font-size: 1.4rem;
        font-weight: $bold;
        line-height: 2;
        max-width: 54.5rem;
        margin-bottom: $spacing-l;
        color: $blog-header-summary-colour;
    }

    &__summary--mobile {
        font-weight: $bold !important; // stylelint-disable-line declaration-no-important
    }

    &__meta {
        font-size: 1.4rem;
        padding: $spacing-m 0 $spacing-l;
        color: $blog-header-meta-colour;
        border-top: $blog-header-meta-border-top-height solid $blog-header-meta-border-top-colour;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 1;
    }

    &__date {
        font-size: 1.4rem;
    }

    &__read-time {
        font-size: 1.4rem;
    }

    &__read-time,
    &__author,
    &__caption {

        &::before {
            font-size: 1.3rem;
            display: inline;
            margin-right: .8rem;
            margin-left: .5rem;
            content: $blog-header-meta-divider;
        }
    }

    &__caption {
        font-size: 1.4rem;
        font-weight: $regular;
        float: right;

        &::before {
            display: none;
        }
    }

    &__content {
        position: relative;
        max-width: 72rem;
        margin: 0 auto;
    }

    &__share {
        position: absolute;
        top: 0;
        right: -27rem;
        width: 8rem;
        min-height: 30rem;
    }

    &__cta {
        position: relative;
        max-width: 72rem;
        margin: 0 auto;
        padding: 4.4rem 0;
        text-align: center;

        &::before {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;
            width: 100%;
            height: .1rem;
            margin: 0 auto;
            content: '';
            background-color: $blog-cta-line-colour;
        }
    }

    &__cta-button {
        @include cta-primary;
    }

    &__progress {
        position: absolute;
        bottom: -1rem;
        left: 0;
        width: 100%;
        height: 1rem;
        background-color: $blog-progress-bar-colour;
    }

    &__progress-bar {
        z-index: $level-one;
        display: block;
        width: 100%;
        height: 1rem;
        transition: transform ease .2s;
        transform: scaleX(0);
        transform-origin: left;
        background-color: $blog-progress-bar-line-colour;

        &.is-glued {
            position: fixed;
        }
    }
}

@include mq($until: 1300px) {

    .blog {

        &__share {
            right: -17rem;
        }
    }
}

@include mq($until: 1100px) {

    .blog {

        &__share {
            right: -10rem;
        }
    }
}

@include mq($until: 1000px) {

    .blog {

        &__share {
            display: none;
        }
    }
}

@include mq($until: phablet) {

    .blog {

        &__header {
            margin-bottom: 3.2rem;
        }

        &__share {
            display: none;
        }

        &__summary {
            display: none;
        }

        &__meta {
            padding: 1rem 0 1.1rem;
        }

        &__caption {
            font-size: 1.2rem;
            font-weight: $bold;
        }

        &__heading {

            &--small {
                @include headline-4;
            }
        }
    }
}

@include mq($until: mobile) {

    .blog {

        &__cta {

            &::before {
                content: normal;
            }
        }

        &__cta-button {
            width: 100%;
            text-align: center;
        }
    }
}
