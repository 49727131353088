// Styles for: Region listing widget
// Notes: Region listing widget style for all devices

$region-listing-background-colour: $white !default;
$region-listing-background-image: 'bg--light.jpg' !default;
$region-listing-title-colour: $dark-1 !default;
$region-listing-title-line-colour: $primary !default;
$region-listing-paging-colour: $dark-3 !default;
$region-listing-arrow-button-line-colour: $dark-1 !default;
$region-listing-arrow-button-colour: $white !default;
$region-listing-arrow-button-colour-hover: $primary !default;
$region-listing-arrow-button-icon-colour: $dark-1 !default;
$region-listing-arrow-button-icon-colour-hover: $white !default;
$region-listing-arrow-button-border-radius: .2rem !default;
$region-listing-image-shim: $black !default;
$region-listing-image-background-colour: $dark-1 !default;
$region-listing-regions-scrollbar: $light-1 !default;
$region-listing-regions-scrollbar-thumb: $primary !default;
$region-listing-item-title-line-colour: $white !default;

$hasBackgroundImage: false;

.region-listing {
    $this: &;
    padding: 5rem 0;
    overflow: hidden;

    @if $hasBackgroundImage {
        background-image: url($img-dir + '/elements/' + $region-listing-background-image);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    @else {
        background-color: $region-listing-background-colour;
    }

    &__regions {
        position: relative;
        display: flex;
        margin: 0 -1.5rem;
        margin-top: 1.6rem;
        transition: transform .8s ease-in-out;
    }

    &__title {
        font-size: 3.2rem;
        position: relative;
        margin: 2rem 0 3.8rem;
        text-align: center;
        color: $region-listing-title-colour;

        &::after {
            position: absolute;
            bottom: -1rem;
            left: calc(50% - 18.8rem);
            width: 37.6rem;
            height: .2rem;
            content: '';
            background-color: $region-listing-title-line-colour;
        }

        @include mq($until: phablet) {
            font-size: 2rem;
            margin-bottom: 2.8rem;

            &::after {
                left: calc(50% - 11.3rem);
                width: 22.6rem;
            }
        }
    }

    &__top {
        position: relative;
        height: 4.4rem;
        margin: 0 5.5rem 4.4rem;
    }

    &__page {
        font-size: 1.3rem;
        line-height: 1;
        color: $region-listing-paging-colour;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
    }

    &__controls {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__arrow-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        margin: 0 0 0 .8rem;
        padding: 0;
        border: .2rem solid $region-listing-arrow-button-line-colour;
        border-radius: $region-listing-arrow-button-border-radius;
        background-color: $region-listing-arrow-button-colour;

        @include hover {
            border: none;
            background-color: $region-listing-arrow-button-colour-hover;

            #{$this}__icon {
                fill: $region-listing-arrow-button-icon-colour-hover;
            }
        }

        #{$this}__icon {
            width: 1.1rem;
            height: 1.1rem;
            fill: $region-listing-arrow-button-icon-colour;
        }

        &:disabled {
            pointer-events: none;
            opacity: .3;
        }
    }

    &__regions-container {
        position: relative;
        width: 100%;
    }

    // REGION

    .region {
        position: relative;
        flex-shrink: 0;
        width: 33.3%;
        padding: 0 1.5rem;
        transition: opacity ease-in-out .75s;
        opacity: 1;

        &.is-hidden {
            pointer-events: none;
            opacity: .3;
        }

        &__inner {
            position: relative;
            display: block;
            width: 100%;
            height: 34rem;
            text-decoration: none;
            color: $white;

            &::before {
                position: absolute;
                z-index: $level-one;
                bottom: 0;
                display: block;
                width: 100%;
                height: 50%;
                content: '';
                background-image: linear-gradient(to bottom, transparent, rgba($black, .6));
            }
        }

        &--fade {
            pointer-events: none;
            opacity: .5;
        }

        &__image {
            width: 100%;
            height: 100%;
        }

        .lazy-image-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $region-listing-image-background-colour;
        }

        &__content {
            position: relative;
            z-index: $level-two;
            width: 100%;
            height: 100%;
        }

        &__type {
            @include subtitle-3;
            @include truncate;
            position: relative;
            z-index: $level-one;
            display: block;
            margin-bottom: $spacing-l;
            padding-bottom: $spacing-m;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.15rem;

            &::after {
                position: absolute;
                bottom: .7rem;
                left: 50%;
                width: 10rem;
                height: .1rem;
                content: '';
                transform: translateX(-50%);
                background-color: $region-listing-item-title-line-colour;
            }
        }

        &__title {
            @include text-title;
            font-size: 3.2rem;
            line-height: 1.1;
            text-align: center;
        }

        &__title-container {
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            padding: 0 2rem 4rem;
        }
    }

    // DESKTOP

    @include mq($until: desktop) {

        .region {
            width: 50%;
        }
    }

    @include mq($until: tablet) {
        padding: 3rem 0;

        &__top {
            height: auto;
            margin: 0 0 1rem;
        }

        &__buttons {

            &.is-active {
                display: none;
            }
        }

        &__controls {
            display: none;
        }

        &__regions {
            overflow-x: auto;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin-top: 0;
            padding: 1.6rem 0;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                height: .3rem;
                background: $region-listing-regions-scrollbar;
            }

            &::-webkit-scrollbar-thumb {
                color: $region-listing-regions-scrollbar-thumb;
                background: $region-listing-regions-scrollbar-thumb;
            }
        }

        // REGION

        .region {
            width: 27.8rem;
            margin: 0;
            padding: 0 1.6rem;
            scroll-snap-align: start;

            &.is-hidden {
                pointer-events: all;
                opacity: 1;
            }

            &__inner {
                height: 30rem;
            }

            &__title {
                font-size: 1.8rem;
                line-height: .93;
            }

            &__type {
                font-size: 1.2rem;
                line-height: 1.2;
                margin-bottom: $spacing-m;
            }
        }
    }
}
