// Styles for: form
// Notes: form style for all devices

// stylelint-disable

// styling for standard fieldset

fieldset {
    @include u(margin, 0);
    @include u(padding, 0);
    border: none;
}

.fieldset {
    @include u(padding, 0, 4, 4, 4);
    @include u(border-radius, 0);
    @include u(margin, 0, 0, 4, 0);
    border: .1rem solid $light-3;
    position: relative;
}

// legend

legend {
    @include u(font-size, 1.8);
    @include u(margin, 0, 0, 1.2, 0);
    line-height: 1;
    display: block;
    font-weight: normal;
}

.legend {
    @include u(font-size, 2.8);
    @include u(margin, 0, 0, 3, 0);
    line-height: 1;
    display: block;
    font-family: $bodyFont;
    font-weight: normal;
}

// label

label,
.label {
    @include subtitle-3;
    color: $grey-900;
    margin-bottom: .5rem;
    display: block;
}

.label-helper {
    @include u(font-size, 1.5);
    @include u(margin, -1, 0, 1.2, 0);
    color: $grey-800;
    line-height: 1.4;
}

// inputs/textareas/radio/checkboxes

input[type='text'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
input[type='search'],
textarea,
select {
    @include u(margin, 0, 0, 2, 0);
    padding: $spacing-s;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: .4rem;
    background-color: $white;
    border: solid .1rem $grey-500;
    display: block;
    font-family: inherit;
    font-size: 1.6rem;
    width: 100%;
    height: 4.8rem;
    color: $dark-3;
    box-sizing: border-box;
    max-width: 80rem;

    &::-webkit-input-placeholder {
        color: $dark-3;
        font-size: inherit;
    }

    &:-moz-placeholder {
        color: $dark-3;
        font-size: inherit;
    }

    &::-moz-placeholder {
        color: $dark-3;
        font-size: inherit;
    }

    &::-ms-input-placeholder {
        color: $dark-3;
        font-size: inherit;
    }

    &:focus {
        border: .1rem solid $grey-800;
        outline: none;
    }

    &:disabled {
        background-color: $grey-100;
        cursor: not-allowed;
        color: $grey-400;
    }
}

textarea {
    @include u(padding, 1.7, 3);
    @include u(min-height, 10);
    @include u(border-radius, 1.5);
}

select {
    @include u(padding, 0, 5, 0, 2);
    line-height: 2.6;
    // hide dropdown arrow in internet explorer

    &::-ms-expand {
        display: none;
    }
}

select[multiple] {
    @include u(padding, 1.5, 3);
    border-radius: 2rem;
    background: $grey-200;
    height: inherit;
}

input[type='checkbox'],
input[type='radio'] {
    @include u(font-size, 1.6);

    + label {
        @include u(margin, 0, 2, 0, 0);
        @include u(font-size, 1.6);
        font-weight: normal;
        color: $dark-3;
        display: flex;
        align-items: center;
    }
}

input[type='file'] {
    @include u(font-size, 1.6);
}

// custom checkboxes and custom radio button

input[type='radio'].custom-radio {
    @include u(padding, 0);
    border: 0;
    clip: rect(0 0 0 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    position: absolute;
    width: .1rem;
    top: 2.3rem;
    margin-left: 1.3rem;
}

input[type='radio'].custom-radio ~ label::before {
    @include u(width, 2.3);
    @include u(height, 2.3);
    @include u(margin-right, 1);
    content: '';
    display: inline-block;
    background: $grey-200;
    border: .4rem solid $white;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    box-shadow: 0 0 0 .1rem $grey-400;
    vertical-align: middle;
    position: relative;
    top: -.1rem;
}

input[type='radio'].custom-radio:focus ~ label::before {
    box-shadow: 0 0 0 .1rem $primary;
}

input[type='radio'].custom-radio:checked ~ label::before {
    background: $primary;
    vertical-align: middle;
}

input[type='checkbox'].custom-checkbox,
input[type='radio'].custom-checkbox {
    @include u(padding, 0);
    border: 0;
    clip: rect(0 0 0 0);
    height: .1rem;
    margin: -.1rem;
    overflow: hidden;
    position: absolute;
    width: .1rem;
    top: 2.3rem;
    margin-left: 1.3rem;
}

input[type='checkbox'].custom-checkbox ~ label::before,
input[type='radio'].custom-checkbox ~ label::before {
    content: '';
    display: inline-block;
    background: transparent;
    border: .1rem solid $grey-500;
    border-radius: .2rem;
    vertical-align: middle;
    position: relative;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: $spacing-s;
}

input[type='checkbox'].custom-checkbox:focus ~ label::before,
input[type='radio'].custom-checkbox:focus ~ label::before {
    border: .1rem solid $primary;
}

input[type='checkbox'].custom-checkbox:disabled ~ label::before,
input[type='radio'].custom-checkbox:disabled ~ label::before,
input[type='checkbox'].custom-checkbox:disabled ~ label,
input[type='radio'].custom-checkbox:disabled ~ label {
    opacity: .5;
}

input[type='checkbox'].custom-checkbox:checked ~ label::before,
input[type='radio'].custom-checkbox:checked ~ label::before {
    content: '';
    vertical-align: middle;
    background-image: url($img-dir + '/elements/tick.svg');
    background-size: 2rem;
    background-position: center;
    background-repeat: no-repeat;
}

input[type='radio'].custom-radio ~ label,
input[type='checkbox'].custom-checkbox ~ label,
input[type='radio'].custom-checkbox ~ label {
    @include u(margin-bottom, 2);
}

// required

.required {
    color: $primary;
}

// input container

.input-container {
    @include u(margin-right, 7);
    position: relative;

    &.is-error {

        input,
        textarea {
            border: .2rem solid o($primary, 50);
        }
    }

    &.is-valid {

        input,
        textarea {
            border: .2rem solid o($primary, 50);
        }
    }
}

// input group

.input-group {
    display: table;
    width: 100%;

    input {
        white-space: nowrap;
        display: table-cell;
        vertical-align: middle;
    }

    .input-group-button {
        @include u(padding-top, 0);
        @include u(padding-bottom, 0);
        @include u(margin, 0);
        display: table-cell;
        text-align: center;
        vertical-align: top;
        width: 1%;

        button {
            @include u(margin-left, 2);
            white-space: nowrap;
        }
    }
}
