// Styles for: Lead image widget
// Notes: Lead image widget style for all devices

$lead-image-background-colour: $grey-300 !default;

$lead-image-title-colour: $white !default;
$lead-image-subtitle-colour: $white !default;
$lead-image-gradient-colour: $black !default;

.lead-image {
    height: 75vh;
    min-height: 49rem;
    max-height: 73rem;
    position: relative;
    background-color: $lead-image-background-colour;

    &::after {
        content: '';
        background: linear-gradient(180deg, rgba($lead-image-gradient-colour, .1), rgba($lead-image-gradient-colour, .4));
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__figure {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .lazy-image-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__image {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__container {
        max-width: 80%;
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: $level-one;
    }

    &__title {
        @include text-title;
        margin-bottom: $spacing-m;
        font-size: 6.4rem;
        color: $lead-image-title-colour;
    }

    &__subtitle {
        font-size: 2.1rem;
        line-height: 1.5;
        color: $lead-image-subtitle-colour;
    }

    @media (max-width: $break-tablet) {
        height: 90vh;
        min-height: 36rem;

        &__container {
            max-width: 95%;
        }

        &__title {
            margin-bottom: 0;
            font-size: 4.3rem;
        }

        &__subtitle {
            font-size: 1.8rem;
        }
    }
}
