
// Styles for: Cookie notice
// Notes: Cookie notice style for all devices

// CONFIG

$cookie-notice-background-colour: $white !default;
$cookie-notice-title-colour: $text-heading !default;
$cookie-notice-copy-colour: $text-copy !default;
$cookie-notice-link-colour: $primary !default;

// COOKIE NOTICE

.cookie-notice {
    width: 100%;
    background-color: $cookie-notice-background-colour;
    padding: 3rem 2rem 1rem;
    position: fixed;
    bottom: 0;
    transition: transform .5s .25s;
    z-index: $level-two;
    box-shadow: 0 -.1rem .2rem 0 rgba($black, .1);
    background-repeat: no-repeat;
    background-position: left -1rem bottom;

    &--hide {
        transform: translateY(100%);
    }

    &__body {
        @include clearfix;
        display: flex;
        align-items: flex-end;

        &--left {
            width: 80%;
            float: left;
            padding-right: 3rem;
        }

        &--right {
            width: 20%;
            float: right;
        }
    }

    &__title {
        font-size: 3.8rem;
        margin-bottom: .5rem;
        color: $cookie-notice-copy-colour;
    }

    &__text {
        font-size: 1.7rem;
        line-height: 1.6;
        color: $cookie-notice-copy-colour;

        a {
            color: $cookie-notice-link-colour;
            text-decoration: none;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    &__by {
        display: block;
    }

    &__button {
        float: right;
        padding: 1.6rem 6rem;
    }
}

@include mq($until: tablet) {

    .cookie-notice {
        padding: 2rem 1rem 1rem;

        .wrapper {
            margin: 0;
        }

        &__body {
            display: block;

            &--left {
                width: 100%;
                display: block;
                padding: 0;
            }

            &--right {
                width: 100%;
                float: left;
                display: block;
            }
        }

        &__title {
            font-size: 2.4rem;
        }

        &__text {
            font-size: 1.4rem;
        }

        &__by {
            display: inline;
        }

        &__button {
            margin: 1rem 0;
            float: left;
        }
    }
}
