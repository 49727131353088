// Styles for: Content Carousel widget
// Notes: Content Carousel widget style for all devices
// stylelint-disable color-named

// CONFIG

// CAROUSEL
$content-carousel-background-color: $dark-1 !default;
$content-carousel-title-size: 4rem !default;
$content-carousel-title-color: $light-1 !default;

// CAROUSEL - MOBILE
$content-carousel-title-size-mobile: 3.6rem !default;

// ARTICLE
$content-carousel-article-title-size: 2.6rem !default;
$content-carousel-article-title-color: $light-1 !default;
$content-carousel-article-summary-size: 1.4rem !default;
$content-carousel-article-summary-color: $light-1 !default;

// ARTICLE - MOBILE
$content-carousel-article-title-size-mobile: 2rem !default;
$content-carousel-article-summary-size-mobile: 1.4rem !default;

// NAVIGATION
$content-carousel-navigation-button-color: $dark-2 !default;
$content-carousel-navigation-icon-color: $light-1 !default;
$content-carousel-indicator-color: $grey-600 !default;
$content-carousel-indicator-color-selected: $primary !default;

// CONTENT CAROUSEL

.content-carousel {
    position: relative;

    &__inner {
        position: relative;
        overflow: hidden;
        padding: $spacing-xxl 0 0;
        user-select: none;
        background-color: $content-carousel-background-color;
    }

    &__wrapper {
        z-index: $level-two;
        touch-action: pan-y;
    }

    &__pattern {
        position: absolute;
        height: 100%;
        opacity: .5;
    }

    &__articles {
        position: relative;
        left: 50%;
        display: flex;
        transition: all .5s ease 0s;
        transform: translateX(-50%);
    }

    &__title {
        color: $content-carousel-title-color;
        text-align: center;
        font-size: $content-carousel-title-size;
        margin-bottom: 4.8rem;
    }
}

.book-now {

    &__button {
        @include cta-white;
    }
}

.slide-article {
    position: relative;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    width: 100%;
    max-width: 114rem;
    padding: 0 3rem;
    transition: transform .5s;
    color: $white;

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 32rem;
        margin: 0 4.85rem;
    }

    &__title {
        font-size: $content-carousel-article-title-size;
        color: $content-carousel-article-title-color;
        font-weight: $bold;
        margin-bottom: $spacing-m;
    }

    &__summary {
        font-size: $content-carousel-article-summary-size;
        color: $content-carousel-article-summary-color;
        line-height: 1.5;
        margin-bottom: $spacing-xl;
    }
}

.content-carousel-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4.8rem 0;

    &__button {
        display: flex;
        width: 4.4rem;
        height: 4.2rem;
        background-color: $content-carousel-navigation-button-color;
        justify-content: center;
        align-items: center;
        opacity: .8;
        transition: opacity .25s ease-in-out;

        @include hover {
            opacity: 1;
        }

        &.disabled {
            opacity: .3;
            pointer-events: none;
        }

        .icon {
            fill: $content-carousel-navigation-icon-color;
            width: 1.2rem;
            height: 1.2rem;
        }
    }
}

.content-carousel-indicator {
    display: flex;
    justify-content: center;
    padding: 0 1rem;

    &__dot {
        font-size: 1rem;
        z-index: $level-one;
        margin: 0 1rem;
        cursor: pointer;
        opacity: .6;
        transition: opacity .25s ease-in-out;

        @include hover {
            opacity: 1;
        }

        &--selected {
            opacity: 1;
        }

        a {
            display: block;
        }
    }

    &__dot-link {
        display: block;
        width: .9rem;
        height: .9em;
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 50%;
        background-color: $content-carousel-indicator-color;
    }
}

@include mq($until: tablet) {

    .content-carousel {

        &__inner {
            padding: 12rem 0 0;
            background-size: cover;
        }

        &__articles {
            transform: translateX(-20rem);
        }

        &__button-left {
            width: 4rem;
            height: 9rem;
        }

        &__button-right {
            width: 4rem;
            height: 9rem;
        }

        &__title {
            font-size: $content-carousel-title-size-mobile;
            margin-bottom: $spacing-xl;
        }
    }

    .book-now {

        &__button {
            width: 100%;
            text-align: center;
        }
    }

    .slide-article {
        flex-direction: column;
        width: 36.7rem;

        &__text {
            width: 100%;
            margin: 0;
            padding: 1.5rem 1rem 0;
            z-index: $level-one;
        }

        &__title {
            font-size: $content-carousel-article-title-size-mobile;
            margin-bottom: 1.5rem;
        }

        &__summary {
            font-size: $content-carousel-article-summary-size-mobile;
            margin-bottom: 3.9rem;
        }
    }

    .content-carousel-indicator {
        z-index: $level-one;
    }

    .content-carousel-navigation {

        &__button {
            background-color: transparent;
        }
    }
}

@include mq($until: phablet) {

    .content-carousel {

        &__inner {
            padding: 9rem 0 0;
        }

        &__articles {
            transform: translateX(-16rem);
        }
    }

    .slide-article {
        width: 32rem;

        &__text {
            width: auto;
        }
    }

    .content-carousel-indicator {
        padding: 3rem 0;

        &__dot {
            margin: 0 .5rem;
        }
    }
}
