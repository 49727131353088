// Styles for: Footer widget
// Notes: Footer widget style for all devices

// CONFIG

$footer-background-colour: $dark-1 !default;
$footer-container-spacing: $spacing-xl !default;
$footer-text-colour: $white !default;
$footer-social-icon-colour: $white !default;
$footer-group-logo-color: $white !default;
$footer-image-background-colour: $black !default;

// FOOTER

.footer {
    position: relative;

    &__top {
        padding: $footer-container-spacing 1.2rem;
        background-color: $footer-background-colour;
    }

    &__container {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;
    }

    &__copyright {
        color: $footer-text-colour;
        font-size: 1.4rem;
        margin: .5rem 4.5rem .5rem 1rem;
        line-height: 1.5;
    }

    &__group-logo {
        width: 13.2rem;
        height: 2rem;
        vertical-align: middle;
        fill: $footer-group-logo-color;
    }

    &__navigation {
        display: flex;
        align-items: center;
        margin-right: auto;
        flex-grow: 1;
    }

    &__links {
        flex-grow: 1;
        color: $footer-text-colour;
        font-size: 1.4rem;

        .linked-list {
            display: flex;
            flex-wrap: wrap;

            &__item:not( :last-child ) {

                &::after {
                    content: '';
                    display: inline-block;
                    width: .2rem;
                    height: .2rem;
                    background-color: $footer-text-colour;
                    border-radius: 50%;
                    vertical-align: middle;
                }
            }

            &__link {
                display: inline-block;
                color: $footer-text-colour;
                text-decoration: none;
                margin: .5rem .8rem .5rem 1rem;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &__social {
        display: flex;
        margin: .5rem 0;
    }

    &__social-item {
        margin: 0 1.5rem;
    }

    &__social-icon {
        width: 2.6rem;
        height: 2.6rem;
        fill: $footer-social-icon-colour;

        @include hover {
            transition: transform .2s;
            transform: scale(1.1);
        }
    }

    &__bottom {
        position: relative;
    }

    &__image {
        position: relative;
        background-color: $footer-image-background-colour;
    }

    &__photo {
        height: 42.4rem;
        display: block;
        position: relative;
    }

    @include mq($until: phablet) {

        &__top {
            padding: $footer-container-spacing 1.2rem 0;
        }

        &__container {
            text-align: center;
        }

        &__social {
            order: 1;
            margin: 0 0 $spacing-xl;
        }

        &__copyright {
            order: 2;
            margin: 0 0 $spacing-xl;
        }

        &__links .linked-list {
            justify-content: center;
        }

        &__navigation {
            order: 3;
            margin: 0 0 $spacing-m 0;
            justify-content: center;
        }

        &__photo {
            height: 32.5rem;
        }
    }
}
