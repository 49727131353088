// stylelint-disable

// flatpickr overrides

.qs-datepicker-container {
    font-size: 1.5rem;
    width: 100%;
    max-width: 40rem;
    border: .1rem solid $light-3;
}

.qs-datepicker {

    .qs-controls {
        background: $white;
        border-bottom: .1rem solid $light-3;
    }

    .qs-square {
        height: 2.4em;
        border-radius: 0;
    }

    .qs-square.qs-active {
        background: $primary;
        color: $white;
    }

    .qs-square:not( .qs-empty ):not( .qs-disabled ):not( .qs-day ):not( .qs-active ):hover {
        background: $grey-300;
    }

    .qs-arrow {
        height: 2.4em;
        width: 2.4em;
        border-radius: 0;
    }
}

// stylelint-enable
