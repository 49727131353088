// Styles for: Featured Tile
// Notes: Tile to display a promo inside of the Featured Content widget

// COLOURS
$featured-tile-background-colour: $dark-1 !default;
$featured-tile-text-colour: $white !default;
$featured-tile-line-colour: $grey-400 !default;

.featured-tile {
    background: $featured-tile-background-colour;
    position: relative;
    display: flex;

    &__inner {
        @include link-reset;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__bg-image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__main-image {
        position: relative;
        max-width: 100%;
        margin: $spacing-xxl $spacing-xxl 15rem;
        z-index: $level-one;

        &::before {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    }

    &__content {
        position: relative;
        z-index: $level-one;
        color: $featured-tile-text-colour;
        text-align: center;
        padding: 0 $spacing-l $spacing-xl;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            min-height: 140%;
            background-image: linear-gradient(to bottom, transparent, rgba($black, .6));
        }
    }

    &__title {
        $font-size: 1.8rem;
        $line-height: 1.44;
        $number-of-lines: 3;
        font-size: $font-size;
        font-weight: $bold;
        line-height: $line-height;
        position: relative;
        z-index: $level-one;
        height: #{ ( $font-size * $line-height ) * $number-of-lines};
        overflow: hidden;
    }

    &__label {
        position: relative;
        z-index: $level-one;
        @include truncate;
        text-transform: uppercase;
        @include subtitle-3;
        padding-bottom: $spacing-m;
        display: block;
        font-weight: $bold;
        letter-spacing: 0.15rem;

        &::after {
            content: '';
            position: absolute;
            bottom: .7rem;
            left: 50%;
            height: .1rem;
            width: 10rem;
            transform: translateX(-50%);
            background-color: $featured-tile-line-colour;
        }
    }

    @include mq($until: desktop) {

        &__main-image {
            margin: $spacing-xl $spacing-xl 5.8rem;
        }

        &__title {
            $font-size: 1.6rem;
            $line-height: 1.5;
            $number-of-lines: 3;
            font-size: $font-size;
            font-weight: $bold;
            line-height: $line-height;
            height: #{ ( $font-size * $line-height ) * $number-of-lines};
        }
    }

    @include mq($until: tablet) {
        scroll-snap-align: start;
    }
}
