// Styles for: Featured article widget
// Notes: Featured content widget style for all devices

// CONFIG

$featured-articles-background-colour: $white !default;
$featured-articles-background-image: 'bg--light.jpg' !default;
$featured-articles-background-image-colour: $dark-1 !default;
$featured-articles-title-colour: $dark-1 !default;
$featured-articles-summary-colour: $dark-1 !default;
$featured-articles-article-title-colour: $dark-1 !default;
$featured-articles-article-type-colour: $dark-1 !default;
$featured-articles-article-type-line-colour: $dark-1 !default;
$featured-articles-article-type-line-colour-hover: $primary !default;
$featured-articles-scrollbar: $light-1 !default;
$featured-articles-scrollbar-thumb: $primary !default;

$hasBackgroundImage: false;

// FEATURED ARTICLES

.featured-articles {
    $this: &;

    @if $hasBackgroundImage {
        background-image: url($img-dir + '/elements/' + $featured-articles-background-image);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    @else {
        background-color: $featured-articles-background-colour;
    }

    .wrapper {
        margin-bottom: 0;
    }

    &__inner {
        display: flex;
        justify-content: center;
        padding: 6rem 0;
    }

    &__introduction {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 42rem;
        margin: 0 4rem;
    }

    &__title {
        font-size: 2.4rem;
        margin-bottom: 1.6rem;
        color: $featured-articles-title-colour;
    }

    &__summary {
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
        line-height: 1.5;
        color: $featured-articles-summary-colour;
    }

    &__button {
        @include cta-primary;

        &--desktop {
            align-self: flex-start;
        }

        &--mobile {
            display: none;
        }
    }

    // ARTICLES

    &__articles {
        position: relative;
        display: flex;
    }

    // ARTICLE

    .article {
        padding: 0 1.6rem;

        @include hover {

            #{$this}__type {

                &::after {
                    background-color: $featured-articles-article-type-line-colour-hover;
                }
            }
        }

        a {
            display: block;
            text-decoration: none;
        }

        &__title {
            font-size: 1.8rem;
            line-height: 1.5;
            text-align: center;
            color: $featured-articles-article-title-colour;
        }

        &__type {
            font-size: 1.4rem;
            font-weight: $bold;
            line-height: 1.6;
            position: relative;
            margin-bottom: 1.2rem;
            padding-bottom: 1.2rem;
            text-align: center;
            text-transform: uppercase;
            color: $featured-articles-article-type-colour;
            letter-spacing: 0.15rem;

            &::after {
                position: absolute;
                bottom: 0;
                left: calc(50% - 4.1rem);
                display: block;
                width: 8.2rem;
                height: .1rem;
                content: '';
                transition: background-color .5s ease-in-out;
                background-color: $featured-articles-article-type-line-colour;
            }
        }

        &__image-container {
            position: relative;
            overflow: hidden;
            width: 25rem;
            height: 33rem;
            margin-bottom: 1.2rem;
            background-color: $featured-articles-background-image-colour;
        }
    }

    @include mq($until: 1200px) {

        &__inner {
            flex-direction: column-reverse;
            max-width: 84.6rem;
            margin: 0 auto;
            padding: 2rem 0;
        }

        &__introduction {
            width: 100%;
            margin: 4rem 0;
            padding: 1.6rem;
        }

        &__summary {
            margin-bottom: 0;
        }

        &__button {
            width: 35rem;
            margin: 4rem auto;
            text-align: center;

            &--desktop {
                display: none;
            }

            &--mobile {
                display: block;
            }
        }
    }

    @include mq($until: 900px) {

        &__inner {
            padding: 2rem 0;
        }

        &__introduction {
            width: 100%;
            margin: 2.4rem 0;
            padding: 0;
        }

        &__title {
            font-size: 2rem;
            line-height: 1.8rem;
            text-align: left;
        }

        &__summary {
            line-height: 1.57;
        }

        &__button {
            width: 100%;
            margin: 2.4rem 0;
            text-align: center;
        }

        &__wrapper {
            margin: 0 -1.6rem;
        }

        // ARTICLES

        &__articles {
            overflow-x: auto;
            align-items: flex-start;
            flex-wrap: nowrap;
            justify-content: flex-start;
            width: 100%;
            margin: 0;
            padding-bottom: 2rem;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                height: .3rem;
                background: $featured-articles-scrollbar;
            }

            &::-webkit-scrollbar-thumb {
                color: $featured-articles-scrollbar-thumb;
                background: $featured-articles-scrollbar-thumb;
            }
        }

        // ARTICLE

        .article {
            flex-shrink: 0;
            margin: 0;
            padding: 0 1.6rem;
            scroll-snap-align: start;

            &__image-container {
                width: 16.2rem;
                height: 21.4rem;
                margin-bottom: 1.2rem;
            }

            &__title {
                width: 16.2rem; // ensures title will wrap
                font-size: 1.4rem;
                line-height: 1.8rem;
                text-align: left;
            }

            &__type {
                font-size: 1.2rem;
                line-height: 1.2;
                text-align: left;

                &::after {
                    left: 0;
                }
            }
        }
    }
}
