// Styles for: Hotel listing widget
// Notes: Hotel listing widget style for all devices

// CONFIG

// PAGING
$hotel-listing-background-colour: $white !default;
$hotel-listing-paging-colour: $dark-3 !default;
$hotel-listing-paging-button-outline: $grey-500 !default;
$hotel-listing-paging-button-background-colour: $white !default;
$hotel-listing-paging-button-background-colour-hover: $grey-500 !default;
$hotel-listing-paging-button-icon-colour: $grey-500 !default;
$hotel-listing-paging-button-icon-colour-hover: $white !default;

// CARD
$hotel-listing-hotel-card-outline: $light-1 !default;
$hotel-listing-hotel-card-image-background: $light-1 !default;
$hotel-listing-hotel-card-name: $dark-1 !default;
$hotel-listing-hotel-card-from: $grey-500 !default;
$hotel-listing-hotel-card-price: $dark-1 !default;
$hotel-listing-hotel-card-no-price: $dark-1 !default;
$hotel-listing-hotel-card-background-colour: $white !default;

// TITLE
$hotel-listing-title-colour: $dark-3 !default;
$hotel-listing-title-line-colour: $primary !default;

// SCROLLBAR
$hotel-listing-hotels-scrollbar: $light-1 !default;
$hotel-listing-hotels-scrollbar-thumb: $primary !default;

// HOTEL LISTING

.hotel-listing {
    $this: &;
    padding-top: $spacing-xxl;
    padding-bottom: $spacing-xxl;
    overflow: hidden;
    position: relative;
    background-color: $hotel-listing-background-colour;

    .wrapper {
        margin-bottom: 0;
    }

    &__title {
        @include headline-4;
        position: relative;
        margin-bottom: 4.8rem;
        text-align: center;

        &::after {
            position: absolute;
            bottom: -1rem;
            left: calc(50% - 18.8rem);
            width: 37.6rem;
            height: .2rem;
            content: '';
            background-color: $hotel-listing-title-line-colour;
        }
    }

    &__subtitle {
        font-size: 1.4rem;
        color: $hotel-listing-title-colour;
    }

    &__page {
        font-size: 1.4rem;
        font-weight: $bold;
        line-height: 1;
        color: $hotel-listing-paging-colour;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__arrow-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        margin: 0 0 0 .8rem;
        border: .1rem solid $hotel-listing-paging-button-outline;
        border-radius: 50%;
        background-color: $hotel-listing-paging-button-background-colour;

        @include hover {
            border: none;
            background-color: $hotel-listing-paging-button-background-colour-hover;

            #{$this}__icon {
                fill: $hotel-listing-paging-button-icon-colour-hover;
            }
        }

        #{$this}__icon {
            width: 1.1rem;
            height: 1.1rem;
            fill: $hotel-listing-paging-button-icon-colour;
        }

        &:disabled {
            pointer-events: none;
            opacity: .3;
        }
    }

    // BUTTON

    &__button-container {
        position: relative;
        clear: both;
        margin-bottom: $spacing-xl;
    }

    &__button {
        @include cta-primary;
        display: block;
        width: max-content;
        margin: 0 auto;
        text-align: center;
    }

    // pos

    &__pos {
        text-align: center;
    }

    &__image {
        height: 4.2rem;
    }

    &__wrapper {
        margin: $spacing-s 0 $spacing-xxl;
    }

    &__hotels {
        position: relative;
        display: flex;
        margin: 0 -#{ $spacing-m };
        transition: transform .8s ease-in-out;
    }
}

.hotel {
    $this: &;
    min-width: 33.33%;
    padding: 0 $spacing-m;
    transition: opacity ease-in-out .75s;
    opacity: 1;

    &.is-hidden {
        pointer-events: none;
        opacity: .3;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        border: .1rem solid $hotel-listing-hotel-card-outline;
        background-color: $hotel-listing-hotel-card-background-colour;
    }

    &__rating {
        width: 5.8rem;
        height: 1rem;
        background-image: url(../i/elements/star-rating.png);
        background-size: 5.8rem 2rem;
        margin-bottom: 1.2rem;

        span {
            display: block;
            height: 1rem;
            background-image: url(../i/elements/star-rating.png);
            background-position-y: -1rem;
            background-size: 5.8rem 2rem;
        }
    }

    &__image {
        position: relative;
        background-color: $hotel-listing-hotel-card-image-background;

        &::before {
            display: block;
            width: 100%;
            padding-top: (9 / 16) * 100%;
            content: '';
        }

        img {
            font-family: 'object-fit: cover; object-position: center';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__name {
        font-size: 2.2rem;
        margin-bottom: $spacing-xxs;
        color: $hotel-listing-hotel-card-name;
    }

    &__supporting {
        font-size: 1.8rem;
        margin-bottom: $spacing-xs;
        line-height: 1.2;
    }

    &__price {
        font-size: 2rem;
        font-weight: $bold;
        line-height: 1.2;
        float: left;
        color: $hotel-listing-hotel-card-price;
    }

    &__from {
        font-size: 1.4rem;
        font-weight: $regular;
        display: block;
        color: $hotel-listing-hotel-card-from;
    }

    &__per {
        font-size: 1.4rem;
        font-weight: $regular;
    }

    &__content {
        @include clearfix();
        display: flex;
        flex: 1 0 0;
        flex-basis: auto;
        flex-direction: column;
        padding: $spacing-xs $spacing-m $spacing-s;
    }

    &__details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
    }

    &__no-price {
        font-size: 1.4rem;
        line-height: 1.4;
        color: $hotel-listing-hotel-card-no-price;
    }

    &__cta {
        float: right;
        margin-left: $spacing-s;
        text-align: center;

        @include cta-primary-outline;
    }
}

// DESKTOP

@include mq($until: desktop) {

    .hotel {

        &__details {
            display: block;
        }

        &__cta {
            width: 100%;
            margin-top: $spacing-s;
            text-align: center;
            margin-left: 0;
        }
    }
}

// TABLET

@include mq($until: tablet) {

    .hotel-listing {
        padding-top: $spacing-xl;
        padding-bottom: $spacing-xl;

        &__hotel {
            min-width: 25rem;
            padding: 0 $spacing-s;
        }

        &__wrapper {
            margin: $spacing-m -#{ $spacing-m } 4.8rem;
        }

        &__hotels {
            overflow-x: scroll;
            flex-wrap: nowrap;
            justify-content: flex-start;
            margin: 0;
            padding-bottom: 2rem;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                height: .3rem;
                background: $hotel-listing-hotels-scrollbar;
            }

            &::-webkit-scrollbar-thumb {
                color: $hotel-listing-hotels-scrollbar-thumb;
                background: $hotel-listing-hotels-scrollbar-thumb;
            }
        }

        &__controls {
            display: none;
        }
    }

    .hotel {
        min-width: 28.5rem;
        scroll-snap-align: start;

        &__name {
            font-size: 1.6rem;
        }

        &.is-hidden {
            pointer-events: all;
            opacity: 1;
        }
    }
}

// PHABLET

@include mq($until: phablet) {

    .hotel-listing {

        &__button {
            width: 100%;
        }
    }

    .hotel {

        &__price {
            font-size: 1.6rem;
        }
    }
}

// HOTEL SKELETON LOADING

.hotel--skeleton {
    pointer-events: none;

    .hotel__name,
    .hotel__supporting,
    .hotel__no-price,
    .hotel__cta::after {
        width: 100%;
        animation-name: skeleton-loading;
        animation-duration: 1s;
        animation-iteration-count: infinite;
    }

    .hotel__name {
        display: inline-block;
        height: 2.4rem;
        max-width: 24.5rem;

        @include mq($until: tablet) {
            height: 2rem;
        }
    }

    .hotel__supporting {
        display: inline-block;
        height: 1.3rem;
        max-width: 3.8rem;
    }

    .hotel__no-price {
        display: block;
        height: 1.5rem;
        max-width: 15rem;
    }

    .hotel__cta {

        &::after {
            content: '';
            display: block;
            height: 1.5rem;
            width: 8rem;
            margin: 0 auto;
        }
    }
}
