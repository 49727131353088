// Styles for: cms content
// Notes: cms content style for all devices

// stylelint-disable selector-max-specificity, selector-max-type, selector-no-qualifying-type

.cms-content {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: $spacing-xs;
        color: $text-heading;

        a {
            color: $primary;
            text-decoration: none;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    h1 {
        @include cms-heading-1;
    }

    h2 {
        @include cms-heading-2;
    }

    h3 {
        @include cms-heading-3;
    }

    h4 {
        @include cms-heading-4;
    }

    h5 {
        @include cms-heading-5;
    }

    h6 {
        @include cms-heading-6;
    }

    > p:first-of-type + ul,
    > p:first-of-type + ol {
        margin-top: 6rem;
    }

    p {
        @include cms-paragraph;
        color: $text-copy;
        margin-bottom: $spacing-l;
    }

    .cta {
        margin-bottom: 2.8rem;
    }

    ul,
    ol {
        @include cms-list;
        color: $text-copy;
        width: auto;
        text-align: left;
    }

    ul {
        margin: 0 0 3rem;
        padding: 0 0 0 1.8rem;

        li {
            position: relative;
            margin: $spacing-xxs 0;
            padding: 0 0 0 .6em;
            list-style-type: disc;

            a {
                text-decoration: none;
                color: $primary;
                font-weight: $bold;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    ol {
        margin: 0 0 3rem;
        padding: 0 0 0 1.8rem;

        li {
            margin: $spacing-xxs 0;
            padding: 0 0 0 .6em;
            list-style-type: decimal;

            a {
                text-decoration: none;
                color: $primary;
                font-weight: $bold;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    dl {
        margin-bottom: 1.2rem;
        margin-top: 0;
        font-size: 1.8rem;
    }

    dt,
    dd {
        line-height: 1.4;
    }

    dd {
        margin-bottom: 1rem;
        margin-left: 0;
    }

    img {
        max-width: 100%;

        &.left {
            padding: .5rem 2rem 1.2rem 0;
            vertical-align: top;
            float: left;
        }

        &.right {
            padding: .5rem 0 1.2rem 2rem;
            float: right;
        }
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote::before,
    blockquote::after,
    q::before,
    q::after {
        content: none;
    }

    em {
        font-style: italic;
        color: $text-copy;
    }

    strong {
        color: $text-copy;
        font-weight: $bold;
    }

    pre,
    code {
        padding: .5rem;
        margin: 0;
        border-radius: .3rem;
        font-size: 1.4rem;
        line-height: 1.2;
        color: $black;
        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-wrap;
        background-color: $grey-300;
        border: .1rem solid $grey-600;
        display: inline-block;
    }

    pre {
        margin: 0 0 3rem;
    }

    hr {
        margin: 3rem 0;
        border: none;
        border-bottom: .1rem solid $light-3;
    }

    address {
        margin: 0 0 1.2rem;
        font-size: 1.6rem;
        line-height: 1.4;
    }

    // Alignment of tags

    audio,
    canvas,
    iframe,
    img,
    svg,
    video {
        vertical-align: middle;
    }

    // Blockquote

    blockquote {
        padding: 0 2.5rem;
        margin: 0;
        display: block;
        clear: both;
        border-left: .7rem solid $primary;

        @include mq($until: mobile) {
            padding: 0 $spacing-xs;
            margin-left: -.7rem;
        }

        p {
            margin-bottom: 0;
            font-size: 2.4rem;
            line-height: 1.2;
            color: $primary;
            font-family: $titleFont;
            font-weight: $regular;
        }
    }

    .table-scrollable {
        margin-bottom: 2.4rem;
        display: block;
        width: 100%;
        overflow-x: auto;

        &::-webkit-scrollbar {
            height: .3rem;
            background: $light-1;
        }

        &::-webkit-scrollbar-thumb {
            color: $primary;
            background: $primary;
        }
    }

    // butt table scrollable to edge when in article
    @include mq($until: mobile) {

        .table-scrollable {
            width: calc(100% + 1.2rem);
        }
    }

    .table-scrollable table,
    table {
        margin-bottom: 1.2rem;
        background: $white;
        table-layout: auto;
        width: 100%;
        font-size: 1.4rem;

        a {
            font-size: 1.4rem;
        }

        caption {
            font-size: 1.3rem;
            background: transparent;
            color: $black;
            margin-bottom: 2rem;
        }

        thead {

            tr th,
            tr td {
                font-weight: $bold;
                padding: 1rem 1.6rem .9rem;
                color: $primary;

                &::after {
                    display: none;
                }

                @include mq($until: mobile) {
                    padding: .6rem 1.6rem .7rem;
                }
            }
        }

        tfoot {
            background: $dark-1;

            tr th,
            tr td {
                padding: .9rem 1.6rem 1rem;
                color: $white;
                background-color: $dark-1;

                &::after {
                    display: none;
                }

                @include mq($until: mobile) {
                    padding: .7rem 1.6rem .6rem;
                }
            }
        }

        tr th,
        tr td {
            padding: 1.7rem 1.6rem;
            text-align: left;
            border-bottom: solid .1rem $light-3;
            position: relative;

            &::after {
                content: '';
                display: block;
                width: .1rem;
                height: calc(100% - 1.4rem);
                background-color: $light-3;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
            }

            @include mq($until: mobile) {
                padding: 1.3rem 1.6rem;
            }
        }

        tbody tr th:last-child,
        tbody tr td:last-child {

            &::after {
                display: none;
            }
        }

        thead,
        tfoot,
        tbody {

            tr th,
            tr td {
                display: table-cell;
            }
        }

        tr td {
            display: table-cell;
        }
    }
}
