// Styles for: Mixed content listing widget
// Notes: Mixed content listing widget style for all devices
// stylelint-disable selector-max-specificity

// CONFIG

$mixed-content-listing-title-colour: $dark-1 !default;
$mixed-content-listing-introduction-colour: $dark-1 !default;
$mixed-content-listing-showing-colour: $dark-1 !default;
$mixed-content-listing-dropdown-filter-title: $dark-1 !default;
$mixed-content-listing-filter-outline-colour: $grey-600 !default;
$mixed-content-listing-label-colour: $dark-1 !default;
$mixed-content-listing-filter-drop-shadow-colour: $black !default;
$mixed-content-listing-filter-background-colour: $white !default;
$mixed-content-listing-filter-list-colour: $dark-1 !default;
$mixed-content-listing-filter-featured-colour: $dark-1 !default;
$mixed-content-listing-tag-filter-title: $dark-1 !default;
$mixed-content-listing-tag-button-color: $dark-1 !default;
$mixed-content-listing-tag-button-border-color: $primary !default;
$mixed-content-listing-tag-button-background-color: $white !default;
$mixed-content-listing-tag-active-button-color: $white !default;
$mixed-content-listing-tag-active-button-color: $primary !default;
$mixed-content-listing-tag-active-button-background-color: $primary !default;
$mixed-content-listing-article-type: $dark-1 !default;
$mixed-content-listing-article-title: $dark-1 !default;
$mixed-content-listing-article-type-hover: $primary !default;
$mixed-content-listing-article-type-line: $dark-1 !default;
$mixed-content-listing-article-background-colour: $dark-1 !default;

// MIXED CONTENT LISTING

.mixed-content-listing {
    padding: 5rem 0;
    text-align: center;

    &__title {
        font-size: 3.2rem;
        position: relative;
        margin: 0 0 1.6rem;
        color: $mixed-content-listing-title-colour;

        @include mq( $until: phablet ) {
            font-size: 2rem;
            margin-bottom: 2.8rem;
        }
    }

    &__introduction {
        font-size: 1.4rem;
        color: $mixed-content-listing-introduction-colour;
        max-width: 43rem;
        margin: 0 auto 4.8rem;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 120rem;
        min-height: 52.4rem;
        margin: auto;
    }

    &__showing {
        font-size: 1.6rem;
        font-weight: $bold;
        color: $mixed-content-listing-showing-colour;
        margin-top: 2.4rem;

        &.is-hidden {
            display: none;
        }
    }

    &__load-more {
        @include cta-primary();
        display: none;
        margin: 3rem 0;

        &--show {
            display: inline-block;
        }
    }

    .filter {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 0;
    }

    // DROPDOWN

    .dropdown-filter {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 2.4rem;

        &__title {
            font-size: 1.6rem;
            width: 100%;
            margin-bottom: 1.6rem;
            color: $mixed-content-listing-dropdown-filter-title;
        }
    }

    // DROPDOWN

    .dropdown {

        &__button {
            display: flex;
            align-items: center;
            width: 34rem;
            height: 4rem;
            padding: 0 1.6rem;
            cursor: pointer;
            user-select: none;
            border: .1rem solid $mixed-content-listing-filter-outline-colour;
            border-radius: .4rem;
        }

        &__label {
            font-size: 1.8rem;
            flex-grow: 1;
            text-align: left;
            color: $mixed-content-listing-label-colour;
            border-right: 1px solid $mixed-content-listing-filter-outline-colour;
            padding-right: 1rem;
            margin-right: 1.6rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &__arrow {
            transform: rotate(180deg);
        }

        &__list {
            font-size: 1.8rem;
            position: absolute;
            z-index: 100;
            display: none;
            width: 34rem;
            padding: 1rem 0;
            text-align: left;
            color: $mixed-content-listing-filter-list-colour;
            background-color: $mixed-content-listing-filter-background-colour;
            box-shadow: 0 .4rem .8rem 0 rgba($mixed-content-listing-filter-drop-shadow-colour, .2), 0 .6rem 2rem 0 rgba($mixed-content-listing-filter-drop-shadow-colour, .2);

            &--show {
                display: block;
            }
        }

        &__item {
            width: 100%;
            padding: 1rem 2rem;
            text-align: left;

            @include hover {
                text-decoration: underline;
            }

            &--featured {
                border-top: .1rem solid $mixed-content-listing-filter-featured-colour;
                border-bottom: .1rem solid $mixed-content-listing-filter-featured-colour;
            }
        }
    }

    // TAG

    .tag {
        display: flex;
        overflow: auto;
        align-items: flex-start;
        flex-direction: column;
        max-width: 100%;
        margin-bottom: 2.4rem;

        &__title {
            font-size: 1.6rem;
            width: 100%;
            margin-bottom: 1.6rem;
            color: $mixed-content-listing-tag-filter-title;
        }

        &__options {
            display: flex;
            justify-content: space-between;
        }

        &__button {
            @include cta-dark-1-outline();
            font-size: 1.4rem;
            overflow: hidden;
            height: 4.2rem;
            margin: 0 .5rem;
            padding: 0 2.4rem;
            white-space: nowrap;
            text-transform: none;
            border-radius: 100rem;

            @include hover ($focus: false) {
                color: $mixed-content-listing-tag-button-color;
                border-color: $mixed-content-listing-tag-button-border-color;
                background-color: $mixed-content-listing-tag-button-background-color;
            }

            &.is-active {
                color: $mixed-content-listing-tag-active-button-color;
                border-color: $mixed-content-listing-tag-active-button-color;
                background-color: $mixed-content-listing-tag-active-button-background-color;
            }
        }
    }

    // ARTICLE

    .article {
        width: 25rem;
        margin: 2.4rem 1.8rem;

        @include hover {

            .article__type {

                &::after {
                    background-color: $mixed-content-listing-article-type-hover;
                }
            }
        }

        a {
            text-decoration: none;
        }

        &__title {
            font-size: 1.8rem;
            line-height: 1.5;
            text-align: center;
            color: $mixed-content-listing-article-title;
        }

        &__type {
            font-size: 1.4rem;
            font-weight: $bold;
            line-height: 1.6;
            position: relative;
            margin-bottom: 1.2rem;
            padding-bottom: 1.2rem;
            text-align: center;
            text-transform: uppercase;
            color: $mixed-content-listing-article-type;
            letter-spacing: 0.15rem;

            &::after {
                position: absolute;
                bottom: 0;
                left: calc(50% - 4.1rem);
                display: block;
                width: 8.2rem;
                height: .1rem;
                content: '';
                transition: background-color .5s ease-in-out;
                background-color: $mixed-content-listing-article-type-line;
            }
        }

        &__image-container {
            overflow: hidden;
            width: 100%;
            height: 32.8rem;
            margin-bottom: 1rem;
            background-color: $mixed-content-listing-article-background-colour;
        }

        &__image {
            position: relative;
            display: block;
            width: 25rem;
            height: 32.8rem;
        }
    }

    &.hide-dropdown-filter {

        .dropdown-filter {
            display: none;
        }
    }

    &.hide-tag-filter {

        .tag {
            display: none;
        }
    }

    @include mq($until: phablet) {

        .article {
            width: 16.2rem;
            margin: 1.5rem .8rem;

            &__image-container {
                height: 10.6rem;
            }

            &__image {
                width: 16.2rem;
                height: 10.6rem;
            }

            &__title {
                font-size: 1.4rem;
                line-height: 1.8rem;
                text-align: left;
            }

            &__type {
                font-size: 1.2rem;
                line-height: 1.2;
                text-align: left;

                &::after {
                    left: 0;
                }
            }
        }

        .mixed-content-listing {

            &__load-more {
                display: none;
                align-items: center;
                justify-content: center;
                margin: 2rem auto;

                &--show {
                    display: flex;
                }
            }

            &__content {
                min-height: 20rem;
            }
        }

        .dropdown {
            max-width: 100%;

            &__button {
                max-width: 100%;
            }

            &__list {
                max-width: 90%;
            }
        }
    }
}
