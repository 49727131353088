// Styles for: Article widget styles
// Notes: Article widget styles for all devices

/* stylelint-disable selector-max-specificity */

// wrapper class that gets added to embed widgets

.articleWidget {
    @include clearfix;
    margin: 0 0 $spacing-l; // consistent margin bottom for widgets inside article

    // reset all vertical padding and margin of
    // actual widget within the embded widget.

    > * { // stylelint-disable-line selector-max-universal
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
        margin-top: 0 !important;// stylelint-disable-line declaration-no-important
    }

    .flex-embed {
        display: flex;

        iframe {
            max-width: 100%;
        }
    }

    &.full-width {

        .flex-embed {
            width: 100%;
        }
    }

    &.left {

        .flex-embed {
            justify-content: flex-start;
        }
    }

    &.center {

        .flex-embed {
            justify-content: center;
        }
    }

    &.right {

        .flex-embed {
            justify-content: flex-end;
        }
    }
}

// Twitter flex-embed

.twitterThumb {
    display: flex;
    margin-bottom: $spacing-l;

    &.left {
        justify-content: flex-start;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        justify-content: flex-end;
    }
}

// YouTube flex-embed

.youtubeThumb {
    display: flex;
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: $spacing-l;
    height: 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
