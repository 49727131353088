// Styles for: variables
// Notes: all site variables and settings

/* Base Font Size */
$pixelBase: 10;

/* Core */
$black: #000;
$white: #fff;
$correct: #408428;
$error: #b40000;

/* Greys */
$grey-100: #fafafa;
$grey-200: #f5f5f5;
$grey-300: #eee;
$grey-400: #c5c5c5;
$grey-500: #9e9e9e;
$grey-600: #7f7f7f;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #262626;

/* Brand */
$primary: #195b9f;
$secondary: #b84c16;
$tertiary: $grey-900;
$quaternary: #fbc02d;
$gradient-angle: linear-gradient(225deg, $quaternary, $primary);
$gradient-horizontal: linear-gradient(to left, $quaternary, $primary);
$gradient-vertical: linear-gradient(to bottom, $quaternary, $primary);
$dark-1: #1c1c1c;
$dark-2: #454545;
$dark-3: #707070;
$light-1: #f4f4f4;
$light-2: #e7e7e7;
$light-3: #d8d8d8;

/* Hover */
$primary-hover: darken($primary, 10%);
$secondary-hover: darken($secondary, 10%);
$tertiary-hover: darken($tertiary, 10%);
$quaternary-hover: darken($quaternary, 10%);
$light-1-hover: darken($light-1, 10%);
$dark-1-hover: darken($dark-1, 10%);

/* Text */
$text-heading: $dark-1;
$text-copy: $dark-1;

/* Images */
$img-dir: '../i';

/* Breakpoints */
$break-mobile: 400px;
$break-phablet: 640px;
$break-tablet: 840px;
$break-desktop: 1025px;
$break-wide: 1300px;

/* Z-Index Layers */
$level-one: 100;
$level-two: 200;
$level-three: 300;
$level-four: 400;
$level-five: 500;
$level-six: 600;

/* Spacing */
$spacing-xxs: .4rem;
$spacing-xs: .8rem;
$spacing-s: 1.2rem;
$spacing-m: 1.6rem;
$spacing-l: 2.4rem;
$spacing-xl: 3.2rem;
$spacing-xxl: 6.4rem;
$spacing-xxxl: 12.8rem;

/* Layout */
$layout-padding: $spacing-l;
$wrapper-width: 136rem;
