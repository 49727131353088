// Styles for: grid
// Notes: grid style for all devices

@mixin wrapper {
    width: 100%;
    max-width: $wrapper-width;
    margin: 0 auto;
    padding: 0 $layout-padding;
    @include clearfix;
}

.wrapper {
    @include wrapper;
    margin-bottom: 2rem;
}

@include mq($until: tablet) {

    .wrapper {
        padding: 0 1.6rem;
    }
}

/* Columns */

/* stylelint-disable selector-max-attribute  */

[class*='col-'] {
    width: 100%;
    padding-left: $layout-padding;
    padding-right: $layout-padding;
    float: left;
}
/* stylelint-enable */

$i: 1;

@while $i < 13 {

    .col-#{$i} {
        width: ( 100% / 12 ) * $i;
    }
    $i: $i + 1;
}

@include mq($until: wide) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-wide {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: desktop) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-desk {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: tablet) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-tab {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: phablet) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-phab {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

@include mq($until: mobile) {

    $i: 1;

    @while $i < 13 {

        .col-#{$i}-mob {
            width: ( 100% / 12 ) * $i;
        }
        $i: $i + 1;
    }
}

/* Block Grids */

/* stylelint-disable selector-max-attribute  */

[class*='block-list'] {
    clear: both;

    > li {
        display: inline-block;
        vertical-align: top;
        margin-right: -.4rem;
        clear: both;
        margin-bottom: $layout-padding * 2;
        padding-left: $layout-padding;
        padding-right: $layout-padding;
        width: 100%;
    }
}
/* stylelint-enable */

@include mq($from: mobile) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i} > li {
            width: ( 100 / $i ) * 1%;
        }
        $i: $i + 1;
    }
}

@include mq($until: wide) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-wide > li {
            width: ( 100 / $i ) * 1%;
        }
        $i: $i + 1;
    }
}

@include mq($until: desktop) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-desk > li {
            width: ( 100 / $i ) * 1%;
        }
        $i: $i + 1;
    }
}

@include mq($until: tablet) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-tab > li {
            width: ( 100 / $i ) * 1%;
        }
        $i: $i + 1;
    }
}

@include mq($until: phablet) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-phab > li {
            width: ( 100 / $i ) * 1%;
        }
        $i: $i + 1;
    }
}

@include mq($until: mobile) {

    $i: 1;

    @while $i <= 4 {

        .block-list-#{$i}-mob > li {
            width: ( 100 / $i ) * 1%;
        }
        $i: $i + 1;
    }
}

/* Scrollable Grid */

/* stylelint-disable declaration-no-important  */

.scroll-grid-tablet > li {
    @include mq($until: tablet) {
        width: 40% !important;
        display: inline-block !important;
        margin-right: 1.6rem;
        padding-left: 0 !important;
        padding-right: 0 !important;
        float: none !important;
        clear: none !important;
    }

    @include mq($until: mobile) {
        width: 75% !important;
    }
}
/* stylelint-enable */
