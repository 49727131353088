// Styles for: Booking widget
// Notes: Booking widget style for all devices
// stylelint-disable selector-max-specificity

// TABS
$booking-tab-text-colour: $white !default;
$booking-tab-background-colour: $dark-2 !default;
$booking-tab-hover-background-colour: $primary !default;
$booking-tab-hover-text-colour: $white !default;
$booking-tab-active-background-colour: $booking-tab-hover-background-colour !default;
$booking-tab-active-text-colour: $white !default;

// ERRORS COLOUR
$booking-error-colour: $primary !default;

// ICONS
$booking-icon-colour: $dark-1 !default;

.booking {
    $this: &;
    padding: 6rem 0;
    overflow: hidden;

    // HIDDEN INPUT

    &__hidden-input {
        display: none;

        &.is-active {
            display: block;
        }
    }

    // TABS

    &__tabs {
        text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: .1rem;
            background-color: $grey-400;
            position: absolute;
            top: 50%;
            margin: 0 auto;
            left: 0;
            right: 0;
            z-index: 0;
        }
    }

    &__tab {
        @include button;
        padding: 1.4rem;
        color: $booking-tab-text-colour;
        background-color: $booking-tab-background-colour;
        flex: 0 1 20rem;
        border-left: .1rem solid $white;
        transition: background-color .25s ease-in-out, color .25s ease-in-out;
        z-index: $level-one;
        text-align: center;

        @include hover {
            background-color: $booking-tab-hover-background-colour;
            color: $booking-tab-hover-text-colour;
        }

        &.is-active {
            background-color: $booking-tab-active-background-colour;
            color: $booking-tab-active-text-colour;
        }
    }

    // PANELS

    &__panel {
        padding: 4rem 0;
        display: none;

        &.is-active {
            display: block;
        }

        &--flights {

            #{$this}__row {
                max-width: 92rem;
                margin: 0 auto 2.4rem;
            }
        }
    }

    &__row {
        @include clearfix();
        margin: 0 -3rem;
        margin-bottom: 4.4rem;
    }

    &__col {
        width: 33.33%;
        float: left;
        padding: 0 3rem;
    }

    &__col-halfwidth {
        width: 50%;
        float: left;
        padding: 0 3rem;
    }

    &__title {
        @include headline-5;
        text-align: center;
        margin-bottom: 4rem;
    }

    &__input-container {
        position: relative;

        #{$this}__form-input {
            padding: $spacing-s $spacing-s $spacing-s 4.8rem;
        }

        // ERROR STATE

        &.has-error {

            #{$this}__form-input {
                border: .1rem solid $booking-error-colour;
            }

            #{$this}__required-text {
                display: block;
            }
        }

        &.destination-error {

            #{$this}__form-input {
                border: .1rem solid $booking-error-colour;
            }

            #{$this}__required-destination {
                display: block;
            }
        }
    }

    &__required-text,
    &__required-destination {
        color: $booking-error-colour;
        margin-top: -1.6rem;
        display: none;
        font-size: 1.2rem;
    }

    &__icon {
        position: absolute;
        pointer-events: none;
        top: 1.2rem;
        left: 1.4rem;
        width: 2.4rem;
        height: 2.4rem;
        fill: $booking-icon-colour;
    }

    &__col-fullwidth {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    // BUTTON

    &__button-container {
        clear: both;
        position: relative;

        &::before {
            content: '';
            width: 100%;
            height: .1rem;
            background-color: $grey-400;
            position: absolute;
            top: 50%;
            margin: 0 auto;
            left: 0;
            right: 0;
            z-index: 0;
        }
    }

    &__button {
        @include cta-primary;
        display: block;
        margin: 0 auto;
        min-width: 20rem;
        text-align: center;
    }

    // LOGOS

    &__logos {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;

        .atout__image {
            width: 15rem;
        }

        .expedia__image {
            width: 18rem;
        }
    }

    &__pos,
    &__partner {
        margin: 0 1.6rem;
    }

    &__checkbox {
        position: relative;
        margin-top: 1rem;
    }
}

@include mq($until: wide) {

    .booking {

        &__col {
            width: 50%;
        }
    }
}

@include mq($until: tablet) {

    .booking {

        &__col {
            width: 100%;
        }

        &__col-halfwidth {
            width: 100%;
            padding: 0;
        }

        &__form {
            padding: 0;
        }
    }
}

@include mq($until: phablet) {

    .booking {
        $this: &;
        padding: 3rem 0;

        &__col {
            margin-bottom: 2rem;
        }

        &__row {
            margin-bottom: 2rem;
        }

        &__tabs {
            max-width: 27rem;
            margin: 0 auto;
        }

        &__panel {

            &--flights {

                #{$this}__row {
                    margin: 0 auto 2rem;
                }
            }
        }

        &__tab {
            padding: 1.5rem;
        }

        &__button-container {
            margin-bottom: 3.3rem;

            &::before {
                top: 8.4rem;
                width: 80%;
            }
        }

        &__button {
            width: 100%;
        }
    }
}
