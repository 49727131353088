// Styles for: Share widget
// Notes: Share widget style for all devices
// stylelint-disable selector-max-specificity

// CONFIG

$share-icon-colour: $grey-500 !default;
$share-icon-hover-colour: $primary !default;
$share-copy-background-colour: $black !default;
$share-copy-text-colour: $white !default;

// SHARE

.share {
    $this: &;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin: 0 0 4.4rem;

    &--stacked {
        flex-direction: column-reverse;
        margin: 0;

        #{$this}__button {
            width: 2.4rem;
            height: 2.4rem;
        }
    }

    &__button {
        width: 3.7rem;
        height: 3.7rem;
        margin: 1rem 2.1rem;
        border-radius: 50%;

        @include hover {

            #{$this}__icon {
                fill: $share-icon-hover-colour;
            }
        }
    }

    &__icon {
        width: 100%;
        height: 100%;
        fill: $share-icon-colour;
        transition: fill .3s ease;
    }

    .copy {
        position: relative;

        &__message {
            font-size: 1.3rem;
            font-weight: $bold;
            position: absolute;
            bottom: 5rem;
            left: 50%;
            width: 13rem;
            padding: .5rem;
            transition: opacity .5s;
            transform: translateX(-50%);
            text-align: center;
            text-transform: uppercase;
            opacity: 0;
            color: $share-copy-text-colour;
            border-radius: .4rem;
            background-color: $share-copy-background-colour;

            &::after {
                position: absolute;
                top: 100%;
                left: calc(50% - .7rem);
                width: 0;
                height: 0;
                content: '';
                border-top: .7rem solid $share-copy-background-colour;
                border-right: .7rem solid transparent;
                border-left: .7rem solid transparent;
            }
        }

        &--show-message {

            .copy__message {
                opacity: .7;
            }
        }
    }
}
