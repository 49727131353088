// Styles for: global
// Notes: global style for all devices

// stylelint-disable rule-empty-line-before, selector-max-universal, selector-max-type, selector-no-qualifying-type

* {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
}

figure {
    margin: 0;
}

ol,
ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    li {
        list-style-type: none;
    }
}

html {
    font: 62.5%/normal $bodyFont;
    color: $text-copy;
    word-wrap: break-word;
    overflow-x: hidden;
    font-weight: $regular;
    height: 100%;
}

html,
body {
    width: 100%;
    background-color: $white;
    -webkit-font-smoothing: antialiased;
}

.icon {
    width: 2rem;
    height: 2rem;
    display: block;
    fill: $black;
}

.skip-to {
    padding: 0 2rem;
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    text-decoration: none;
    transition: .2s;
    background: $primary;
    line-height: 5rem;
    transform: translateX(-100%);
    z-index: 1000;
    font-size: 1.2rem;
    font-weight: $bold;
    text-transform: uppercase;

    &:focus {
        transform: translateX(0);
    }
}

img {
    max-width: 100%;
}

@include mq($from: tablet) {

    *:focus {
        outline: .5rem auto $primary;
    }
}

@include mq($until: tablet) {

    *:focus {
        outline: none;
    }
}

// Mobile Optimisations
* {
    -webkit-tap-highlight-color: none;
}
