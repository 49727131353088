// Styles for: Embeddable quote widget
// Notes: Embeddable quote widget style for all devices

.embeddable-quote {

    &__footer {
        margin-top: 1rem;
        font-size: 1.8rem;
    }
}
